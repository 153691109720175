import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegisterFormFields, IRegisterSubmitField } from "@models/Register";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { ILabInfo } from "@/models/Lab";
import { number } from "yup";

interface orderOwner {
  [key: string]: any;
  employee: any;
  staff: any;
  productClass: any;
  reason: any;
  items: any;
  filter: any;
}

const initialState: orderOwner = {
  employee: {id: null,  name_sei: '', name_mei: ''},
  staff: [],
  labPartner: [],
  productClass: [],
  reason: [],
  items: [],
  filter: { type: '', month: '', year: '' }
};

export const statisticStaffSlice = createSlice({
  name: "statisticStaff",
  initialState,
  reducers: {

    setState(state, action: PayloadAction<any>) {
      const { data, field }: any = action.payload;
      state [ field as string ] = data
    },

    fetchs(state, action: PayloadAction<any>) {
      const { data, User, LabPartner, ProductClass, Reason, filter }: any = action.payload;
        state.employee = User;
        state.labPartner = LabPartner;
        state.productClass = ProductClass;
        state.reason = Reason;
        state.filter = filter;
        state.items = data;
    },
    reset(state){
      Object.assign( state, initialState )
      // state.employee = User;
    }
  },
});

const authReducer = statisticStaffSlice.reducer;
const { fetchs, setState, reset } = statisticStaffSlice.actions;
export { fetchs, setState, authReducer, reset };
