import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { msg } from "@msg";
import type { ITaskRepository } from "@/datasource/repositories/task";

@injectable()
export class TaskViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.TaskRepository)
    private taskRepo: ITaskRepository
  ) {
    super();
  }

  async statusTaskOverLap(params: any):  Promise<any> {
    return await this.withLoading( ()=> this.taskRepo.statusTaskOverLap( params ) ) 
  }

  async addTaskLog( data: any): Promise<any> {
    return await this.taskRepo.addTaskLog( data );
  }

  async updateTaskLog(taskId: any, params: any): Promise<any> {
    return await this.taskRepo.updateTaskLog( taskId, params );
  }


  async updateDuplicateTask( data: any): Promise<any> {
    return await this.taskRepo.updateDuplicateTask( data );
  }

  async fetchDuplicateTask(params: any): Promise<any> {
    return await this.taskRepo.fetchDuplicateTask( params );
  }

  async getStatistic(params: any): Promise<any> {
    let res = await this.taskRepo.getStatistic(params);
    return res;
  }

  async updateOrdersTask(taskId: any, data: any): Promise<any> {
    return await this.taskRepo.updateOrdersTask(taskId, data);
  }

  async deleteTask(taskId: any): Promise<any> {
    return await this.taskRepo.deleteTask(taskId);
  }

  async deleteOrders(taskId: any, orderIds: any ): Promise<any> {
    return await this.taskRepo.deleteOrders(taskId, orderIds );
  }

  async fetchOne(taskId: number): Promise<any> {
    let res = await this.taskRepo.fetchOne(taskId);
    return res;
  }

  async getTask(params: any): Promise<any> {
    let res = await this.taskRepo.getTask(params);
    return res;
  }

  async getHistoryTasks(params: any): Promise<any> {
    let res = await this.taskRepo.getHistoryTasks(params);
    return res;
  }

  async getFreeTime(params: any): Promise<any> {
    let res = await this.taskRepo.getFreeTime(params);
    return res;
  }

  async getTaskLog(params: any): Promise<any> {
    let res = await this.taskRepo.getTaskLog(params);
    return res;
  }

  async startTask(taskId: number): Promise<any> {
    let res = await this.taskRepo.startTask(taskId);
    return res;
  }

  async pauseTask(taskId: number, countTime: number): Promise<any> {
    let res = await this.taskRepo.pauseTask(taskId, countTime);
    return res;
  }

  async startTime(): Promise<any> {
    let res = await this.taskRepo.startTime();
    return res;
  }

  async pauseTime(type: string): Promise<any> {
    let res = await this.taskRepo.pauseTime(type);
    return res;
  }

  async doneTask(taskId: number, data: any): Promise<any> {
    let res = await this.taskRepo.doneTask(taskId, data);
    return res;
  }

  async checkProductIncludeProcess(taskId: number, data: any) : Promise<any> {
    let res = await this.taskRepo.checkProductIncludeProcess(taskId, data);
    return res;
  }
}
