import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface IState {
  modals: any;
  allProcesses: any;
  processes: any;
  categories: any;
  currentCategory: any;
  process: any;
}

const initialState: IState = {
  allProcesses: [],
  processes: {
    data: [],
    currentPage: null,
    total: 0,
    lastPage: null,
    itemPerPage: 10,
    isStopLoading: false,
  },
  process: null,

  categories: {
    data: [],
  },
  currentCategory: null,

  modals: {
    createCategory: false,
  },
};

export const settingProcessSlice = createSlice({
  name: "settingProcess",
  initialState,
  reducers: {
    resetProcesses(state) {
      state.processes = {  
        data: [],
        currentPage: null,
        total: 0,
        lastPage: null,
        itemPerPage: 10,
        isStopLoading: false, 
      };
      state.currentCategory = null
    },

    sortProcesses(state, action) {
      let processes = state.processes;
      let { ids } = action.payload;
      let data = [];

      for (let i = 0; i < ids.length; i++) {
        let find = processes.data.find((item: any) => item.id === ids[i]);
        data.push(find);
      }

      processes.data = [...data];
      state.processes = processes;
    },

    fetchAllProcesses(state, action) {
      let data = action.payload;
      state.allProcesses = data;
    },

    fetchProcesses(state, action) {
      let processes = {...state.processes};
      let data = action.payload;
      if (data.last_page === 1) {
        processes.data = [...data.data];
      } else if(data.last_page >= data.current_page) {
        processes.data = [...processes.data, ...data.data];
      }

      processes.currentPage = data.current_page;
      processes.itemPerPage = data.per_page;
      processes.total = data.total;
      processes.lastPage = data.last_page;
      if (processes.currentPage === data.last_page) {
        processes.isStopLoading = true;
      }else if(processes.currentPage < data.last_page)
      {
        processes.isStopLoading = false;
      }

      state.processes = processes;
    },

    createProcess(state, action) {
      let processes = state.processes;
      let data = action.payload;

      processes.data.push(data);
      state.processes = processes;
    },

    updateProcess(state, action) {
      let processes = state.processes;
      let {index, data} = action.payload;
      if(index !== -1)
      {
        processes.data[index] = { ...data };
      }
      state.processes = processes;
    },

    updateProcesses(state, action) {
      state.processes = action.payload
    },

    fetchProcess(state, action) {
      let process = state.process;
      let data = action.payload;
      state.process = { ...data };
    },

    deleteProcess(state, action) {
      let processes = state.processes;
      let { id } = action.payload;
      let findIndex = processes.data.findIndex((item: any) => item.id === id);
      if (findIndex !== -1) {
        processes.data.splice(findIndex, 1);
      }
      state.processes = processes;
    },

    fetchCurrentCategory(state, action) {
      state.currentCategory = action.payload;
    },
    fetchCategories(state, action) {
      let categories = state.categories;
      let data = action.payload;
      // fetch all categories
      if (Array.isArray(data)) {
        categories.data = [...data];
      } else {
        categories = { ...data };
      }

      state.categories = categories;
    },

    createCategory(state, action) {
      let categories = state.categories;
      let data = action.payload;
      categories.data.push(data);
      state.categories = categories;
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },
  },
  extraReducers: (builder) => {},
});

export default settingProcessSlice;
export const settingProcessReducer = settingProcessSlice.reducer;
export const settingProcessSliceAction = settingProcessSlice.actions;
