import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@di/types";




@injectable()
export class ReasonRepository {
  constructor(
    @inject(TYPES.ReasonService) private reasonService: any
  ) {}
  
  async fetchV2All(params: any): Promise<IDataResponse<any>> {
    let res = await this.reasonService.fetchV2All(params);
    return res;
  }

  async fetchAll(params: any): Promise<IDataResponse<any>> {
    let res = await this.reasonService.fetchAll(params);
    return res;
  }

  async fetch(params: any): Promise<IDataResponse<any>> {
    let res = await this.reasonService.fetch(params);
    return res;
  }

  async create( id: number,data: any): Promise<IDataResponse<any>> {
    let res = await this.reasonService.create( id, data);
    return res;
  }

  async update(id: number, params: any): Promise<IDataResponse<any>> {
    let res = await this.reasonService.update( id, params);
    return res;
  }

  async delete(id: number): Promise<IDataResponse<any>> {
    let res = await this.reasonService.delete(id);
    return res;
  }

  async sort(ids: object ): Promise<IDataResponse<any>> {
    let res = await this.reasonService.sort(ids);
    return res;
  }

}
