import React, { useContext, useMemo } from "react";

export const SlotContext = React.createContext(null);

export function SlotProvider(props: any) {
  let parentSlots = (useContext(SlotContext) || {}) as any;
  let { slots = {}, children } = props;

  let value = useMemo(
    () => 
      // merge parent slots with current slots
      Object.keys(parentSlots)
        .concat(Object.keys(slots))
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: Object.assign(parentSlots[key] || {}, slots[key] || {})
          }),{}
        ),
    [parentSlots, slots]
  ) as any;

  return <SlotContext.Provider value={value}>{children}</SlotContext.Provider>;
}
