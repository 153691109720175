import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { IAuthToken, IUserCredentials } from "@models/auth";
import { loginSuccess, loginFail } from "@/store/auth";
import { setInfo } from "@/store/auth/profile";
import { setLoading, setMsg } from "@/store/global";
import { msg } from "@msg";
import { setLabo } from "@/store/setting";
import { deleteCookie, setCookie } from "cookies-next";
import dayjs from "dayjs";
import { AuthViewModel } from "@/modelviews/auth";
import type { IAuthRepository } from "@repositories/interface/IAuthRepository";
import { setFromFiledCustomSubmit } from "@/store/register";
import { BaseViewLabo } from "../labo/BaseViewLabo";
import { setLoadData } from "@/store/setting";
import { message } from "@/constants/message";
import { typePartner } from "@/constants/typePartner";

@injectable()
export class PartnerViewModel extends BaseViewLabo {
  constructor(@inject(TYPES.PartnerRepository) private partnerRepository: any) {
    super();
  }

  private async profile(): Promise<any> {
    await this.checkProfile();
    return this.hooks.getState().setting.labo;
  }
  /**
   * name
   */
  public async fetchDefaultSort() {
    return this.partnerRepository.fetchDefaultSort()
  }

  public linkComeBack() {
    let { items, filter_name } = this.hooks.getState().setting.partner;

    if (!filter_name) return { link: "/setting/partner" };

    let index = typePartner.findIndex((val: any) => val == String(filter_name));
    return { link: `/setting/partner?type=${index}` };
  }

  public async renderData(params: { [key: string]: any }, callback: Function, isLoading: boolean = true) {
    params = Object.keys(params)
      .filter((key) => params[key])
      .reduce((cur, key) => Object.assign(cur, { [key]: params[key] }), {});

    let res: any = isLoading ? (await this.withLoading(() => this.partnerRepository.fetchAll({ params }) )) : (await this.partnerRepository.fetchAll({ params }));

    if (res.success) {
      const { sort_by, sort_type } = params;
      res.data.filter_name = params.filter_name || "";
      res.data.keyword = params.keyword || "";
      res.data.sort = { sort_by, sort_type };
      //sort
      return callback(res);
    }
  }

  public async getclinicsMore(params: {
    keyword: string;
    page: number;
    limit: number;
  }): Promise<any> {
    let res: any = await this.withLoading(() =>
      this.partnerRepository.getclinics({ params })
    );

    if (res.success) {
      let { items }: any = this.hooks.getState().setting.clinics;
      res.data.data = [...items, ...res.data.data];

      return this.hooks.dispatch(
        setLoadData({ ...res.data, keyword: params.keyword, type: "clinics" })
      );
    }
  }

  public async fetchs(param: any): Promise<any> {

    return await this.partnerRepository.fetchAll({ params: param })
    //this.withLoading(() =>  );

    // if ( res.success)
    // return this.hooks.dispatch( setLoadData({ ...res.data, keyword: keyword , type: 'partner' }) );
  }

  public async clinics(): Promise<any> {
    const { keyword, page, limit } =
      this.hooks.getState().register.fromFiledCustom;

    const res: any = await this.withLoading(() =>
      this.partnerRepository.getclinics({ params: { keyword, page, limit } })
    );
    if (res.success)
      return this.hooks.dispatch(
        setLoadData({ ...res.data, keyword: keyword, type: "clinics" })
      );
  }

  public async fetchclinics(params: any): Promise<any> {
    return await this.withLoading(() => this.partnerRepository.getclinics({ params }) );
  }

  public async getclinics(params: {
    keyword: string;
    page: number;
    limit: number;
  }): Promise<any> {
    const res: any = await this.withLoading(() =>
      this.partnerRepository.getclinics({ params })
    );
    if (res.success)
      return this.hooks.dispatch(
        setLoadData({ ...res.data, keyword: params.keyword, type: "clinics" })
      );
  }

  public async dispatchClinics(action: string): Promise<any> {
    const { id } = this.hooks.router.query;

    let res: any;
    let item: any;
    let field: any = [
      "clinic_corp",
      "clinic_name",
      "clinic_name_f",
      "clinic_phone",
      "clinic_fax",
      "clinic_zip",
      "clinic_address",
      "clinic_building",
      "pref_id",
      "city_id",
      "clinic_id",
      "clinic_code",
    ];

    switch (action) {
      case "create_from_clinics":
        res = await this.withLoading(() =>
          this.partnerRepository.getclinics({ params: { clinic_id: id } })
        );
        if (!res.success) 
        return this.hooks.router.back();
        item = res.data.data[0];
        break;
      case "edit":
        res = await this.withLoading(() => this.partnerRepository.fetch(id));

        if (!res.data) 
        return this.hooks.router.back();

        item = res.data.Clinic;
        item.ios_flg = res.data.ios_flg;
        item.clinic_code = res.data.clinic_code;
        field = [...field, "ios_flg"];
        break;
      default:
        return true;
        break;
    }
    // push("/404")
    if (!item) 
      return this.hooks.router.back();

    item = { ...item, clinic_id: id };

    Object.keys(item)
      // .filter( (val) => field.includes(val as string) )
      .forEach((key) => {

        if (item[key] != null )
          this.hooks.dispatch( setFromFiledCustomSubmit({ value:  item[key].toString() , name: key.toString(), }) );

      });
  }

  public async fetch(): Promise<any> {

    const type: any = this.hooks.router.query.type
      ? typePartner[String(this.hooks.router.query.type)]
      : "";

    this.hooks.dispatch(
      setFromFiledCustomSubmit({ value: type, name: "filter_name" })
    );

    const params = this.hooks.getState().register.fromFiledCustom;
    await this.renderData(params, (res: any) => {
      this.hooks.dispatch(setLoadData({ ...res.data, type: "partner" }));
    });
  }

  public async partnerUpdate(id: Number, param: any): Promise<any> {
      return await this.withLoading(() => this.partnerRepository.update(id, param) );
  }

  public async update(param: any): Promise<any> {
    const { id } = this.hooks.router.query;

    const res: any = await this.withLoading(() =>
      this.partnerRepository.update(id, param)
    );

    this.setMsg(res.message || message.success, 1);
    const { link } = this.linkComeBack();
    this.hooks.dispatch(
      setLoadData({ data: [], last_page: 1, current_page: 1, type: "partner" })
    );
    this.hooks.router.push(link);
  }

  public async create(param: any, action: string): Promise<any> {
    await this.checkProfile();

    switch (action) {
      case "create_from_clinics":
        const include = ["clinic_id", "ios_flg", 'clinic_code'];
        const { keyword } = this.hooks.getState().setting.clinics;
        param = Object.keys(param)
          .filter((key) => include.includes(key as string) && param[key])
          .reduce((cur, key) => Object.assign(cur, { [key]: param[key] }), {});
        this.hooks.dispatch(
          setLoadData({ data: [], keyword, type: "clinics" })
        );
        break;
    }

    const res: any = await this.withLoading(() =>
      this.partnerRepository.create(param)
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    //await this.renderData( );

    this.setMsg(res.message || message.success, 1);
    this.hooks.router.push(`/setting/partner/clinics`);
  }

  public async delete(): Promise<any> {
    const { id } = this.hooks.router.query;
    const res: any = await this.withLoading(() =>
      this.partnerRepository.delete(id)
    );

    this.setMsg(res.message || message.success, 1);
    const { link } = this.linkComeBack();
    this.hooks.dispatch(
      setLoadData({ data: [], last_page: 1, current_page: 1, type: "partner" })
    );
    this.hooks.router.push(link);
  }
  //sort
  public async sort(ids: ArrayBuffer): Promise<any> {
    await this.checkProfile();
    const { labo } = this.hooks.getState().setting;
    const { id, type } = this.hooks.router.query;

    const res: any = await this.withLoading(() =>
      this.partnerRepository.sort({ ids })
    );

    if (!res.success) {
      this.setMsg(res.message || message.failed, -1);
      return res;
    }

    const { items, current_page, last_page } =
      this.hooks.getState().setting.branch;
    //await this.renderData( );
    this.setMsg(res.message || message.success, 1);
    return true;
  }
}
