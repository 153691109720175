import iconSearch from "@images/icon_search.svg";
import iconSearch2 from "@images/icon_search2.svg";
import iconArrowBack from "@images/arrowback.svg";
import iconArrowBack2 from "@images/arr_backbold.svg";
import iconArrowDown from "@images/arrowdown.svg";
import iconArrowUp from "@images/arrowup.svg";
import iconBarCode from "@images/ico_barcode.svg";
import iconCapture from "@images/ico_capture.svg";
import icon01 from "@images/ico1.svg";
import icon02 from "@images/ico2.svg";
import icon03 from "@images/ico3.svg";
import icon04 from "@images/ico4.svg";
import icon05 from "@images/ico5.svg";
import icon06 from "@images/ico6.svg";
import icon07 from "@images/ico7.svg";
import icon08 from "@images/ico8.svg";
import iconCheck from "@images/icon_check.svg";
import iconQuestion from "@images/icon_question.svg";
import iconPlus from "@images/ico_plus.svg";
import logo2 from "@images/logo2.svg";
import iconQ from "@images/ico-q.svg";
import Image2 from "@images/Image2.png";
import iconDummy from "@images/icondum.svg";
import logoWhite from "@images/logo.svg";
import txtLogoEvm from "@images/txtevm.svg";
import iconStar from "@images/ico_star.svg";
import iconDrag from "@images/drag.svg";
import iconPlusCircle from "@images/ico_plus_circle.svg";
import iconClose from "@images/close.svg";
import cardbrand from "@images/cardbrand.png";
import icoExcla from "@images/ico_excla2.svg";
import iconPrev from "@images/prev.svg";
import iconArrDownload from "@images/arr_downbold.svg";
import iconNext from "@images/next.svg";
import iconMinus from "@images/ico_minus.svg";
import iconMore from "@images/ico-more.svg";
import iconDel from "@images/ico_del.svg";
import switchCamera from "@images/switchCamera.svg";
import iconCheck3 from "@images/ico_check3.svg";
import iconSetting from "@images/ico_setting.svg";
import iconPrinter from "@images/ico_printer.svg";
import iconCapturePhoto from "@images/capture.svg";
import iconSwitchPhoto from "@images/switchPhoto.svg";
import iconInfo from "@images/ico_info.svg";
import iconUser from "@images/ico_user.svg";
import nextGray from "@images/ico_nextgray.svg";
import iconPreSmall from "@images/ico_prev.svg";
import iconNextSmall from "@images/ico_next.svg";
import iconStop from "@images/ico_stop.svg";
import iconStart from "@images/ico_play.svg";
import iconCheck2 from "@images/ico_check2.svg";
import iconRemove from "@images/icon_remove.svg";
import icoSeting from "@images/setting.svg";
import icoLogout from "@images/logout.svg";
import iconPlay from "@images/play.svg";
import iconPause from "@images/pause.svg";
import icoExcla1 from "@images/ico_excla.svg";
import icoBar from "@images/ico_bar.svg";
import icoArr from "@images/arr.svg";
import icoProject from "@images/ico_project.svg";
import cardimg01 from "@images/cardimg01.png";
import cardimg02 from "@images/cardimg02.png";
import cardimg03 from "@images/cardimg03.png";
import iconCopy from "@images/ico_copy.svg";
import thumb1Image from "@images/thumb_img1.svg";
import thumb2Image from "@images/thumb_img2.svg";
import imgDum2 from "@images/imgdum2.png";
import imgDum from "@images/imgdum.png";
import rectanglePrev from "@images/rectangle_prev.svg";
import rectangleNext from "@images/rectangle_next.svg";
import iconArrowDownGray from "@images/arrowdown-gray.svg";
import iconZoom from "@images/ico_zoom.svg";
import defaultIcon from "@images/demotooth.jpg";
import icoexcla3 from "@images/ico_excla3.svg";
import iconZoomIn from "@images/ico_zoom_in.svg";
import testEVM from "@images/txtevm.svg";
import testEVMblue from "@images/txtevmblue.svg";
import rank1 from "@images/rank1.svg";
import rank2 from "@images/rank2.svg";
import rank3 from "@images/rank3.svg";
import line from "@images/line.svg";

export const imgAssets = {
  iconStar,
  logo2,
  iconQ,
  Image2,
  iconPlus,
  iconSearch,
  iconSearch2,
  iconArrowBack,
  iconArrowBack2,
  iconArrowDown,
  iconArrowUp,
  iconBarCode,
  iconCapture,
  icon01,
  icon02,
  icon03,
  icon04,
  icon05,
  icon06,
  icon07,
  icon08,
  iconCheck,
  iconQuestion,
  iconDummy,
  logoWhite,
  iconDrag,
  iconPlusCircle,
  iconClose,
  cardbrand,
  icoExcla,
  iconPrev,
  iconArrDownload,
  iconNext,
  iconMinus,
  iconMore,
  txtLogoEvm,
  iconDel,
  switchCamera,
  iconCheck3,
  iconSetting,
  iconPrinter,
  iconCapturePhoto,
  iconSwitchPhoto,
  iconInfo,
  iconUser,
  nextGray,
  iconPreSmall,
  iconNextSmall,
  iconStop,
  iconStart,
  iconCheck2,
  iconRemove,
  icoSeting,
  icoLogout,
  iconPlay,
  iconPause,
  icoExcla1,
  icoBar,
  icoArr,
  icoProject,
  icoexcla3,
  cardimg01,
  cardimg02,
  cardimg03,
  iconCopy,
  thumb1Image,
  thumb2Image,
  imgDum,
  imgDum2,
  rectanglePrev,
  rectangleNext,
  iconArrowDownGray,
  iconZoom,
  iconZoomIn,
  defaultIcon,
  testEVM,
  testEVMblue,
  rank1,
  rank2,
  rank3,
  line,
};
