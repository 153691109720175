import React, { useState, useEffect } from "react";
import moment from "moment";
import Image from "next/image";
import { imgAssets } from "@/constants/img";
import Link from "next/link";

interface TaskProps {
  task: any;
  startTask: (taskId: number) => void;
  pauseTask: (taskId: number, countTime: number) => void;
  doneTask: () => void;
}

export const Task = (props: TaskProps) => {
  const [time, setTime] = useState(["", "", ""]);
  
  const getTotalTime = () => {
    let totalTime = parseInt(props.task?.working_time ?? 0);
    if (props.task.last_start_time && props.task.status == "doing") {
      let lastStart = moment(props.task.last_start_time);
      
      let serverTime = moment(props.task.now);
      let diffTime = moment.duration(moment(props.task.clientTime).diff(serverTime)).asSeconds();
      lastStart = lastStart.add(diffTime, "second");
      
      let duration = moment.duration(moment().diff(lastStart));
      totalTime += Math.abs(duration.asSeconds());
    }

    const hours = Math.floor(totalTime / 3600);
    const minutes = Math.floor((totalTime - hours * 3600) / 60);
    const seconds = Math.floor(totalTime - hours * 3600 - minutes * 60);
    
    return {
      totalTime,
      hours,
      minutes,
      seconds
    }
  };
  
  const handlePauseTask = () => {
    props.pauseTask(props.task.id, getTotalTime().totalTime);
  }
  
  const getTotalTimeText = () => {
    const {hours, minutes, seconds} = getTotalTime();
    return [
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`,
      `${seconds.toString().padStart(2, "0")}`,
    ];
  }
  
  const getProcessBar = () => {
    const taskTime = parseFloat(props.task?.total_time ?? props.task?.Task?.time) * 60;
    const {totalTime} = getTotalTime();
    let percent = 0;
    let overTime = false;
    if(totalTime > taskTime) {
      percent = Math.floor( (taskTime * 100) / totalTime )
      overTime = true;
    }
    else {
      percent = Math.floor( (totalTime * 100) / taskTime)
    }
    return {
      percent,
      overTime
    }
  }

  useEffect(() => {
    const loop = setInterval(() => {
      setTime([
        moment().format("YYYY年MM月DD日"),
        moment().format("HH:mm"),
        moment().format("ss"),
      ]);
    }, 100);
    
    return () => {
      clearInterval(loop);
    }
  });

  return (
    <div className={`item ${props.task?.done_part_flg && !props.task.done_time ? 'task-red': ''}`}>
      <Link href={`/order/task/${props.task.id}`}
        onClick={(e) => { 
          if(props.task.done_time) {
            e.preventDefault()
          }
        }}
      >
        {props.task.status == "completed" && (
          <div className="item_mark">
            <div className="ico">
              <Image unoptimized={true} src={ imgAssets.iconCheck2 } alt="" /><span>実施済</span>
            </div>
            <ul>
              <li>
                目標
                <span>
                  {Math.round(
                    (props.task?.total_time ?? props.task?.Task?.time) /
                      (props.task.total_product ?? 1)
                  )}
                  分/個
                </span>
              </li>
              <li>
                自己ベスト<span>{props.task.last_start_time ? Math.round(props.task?.min_time) : ''}分/個</span>
              </li>
              <li>
                本日の平均<span>{props.task.last_start_time ? Math.round(props.task?.average_time) : ''}分/個</span>
              </li>
            </ul>
          </div>
        )}

        <div className="item_head stoped dflex dflex_start">
          {["pause", "confirmed", "completed"].indexOf(props.task.status) !=
            -1 && (
            <div
              className="ico blockico blockico_blue"
              onClick={(e) => {
                e.preventDefault();
                props.startTask(props.task.id);
              }}
            >
              <Image unoptimized={true} src={imgAssets.iconPlay} alt="" />
              <span>計測開始</span>
            </div>
          )}
          {["doing"].indexOf(props.task.status) != -1 && (
            <div
              className="ico blockico blockico_red"
              onClick={(e) => {
                e.preventDefault();
                handlePauseTask();
              }}
            >
              <Image unoptimized={true} src={imgAssets.iconPause} alt="" />
              <span>一時停止</span>
            </div>
          )}
          <div> 
            <label>{ props.task.Task?.ProcessCategory?.name }</label>
            <h4>{ props.task.Task?.name }</h4>
          </div>
        </div>
        <div className="item_info dflex dflex_start">
          <div className="ins">
            <p className="text_bold">指示書</p>
            <p className="num fontArial">
              {props.task.count_order ?? 0}
              <small>枚</small>
            </p>
          </div>
          <div className="ins">
            <p className="text_bold">獲得予定</p>
            <p className="num fontArial">
              {props.task?.total_time
                ? props.task?.total_point
                : props.task.Task?.target_point}
              <small>Pt</small>
            </p>
          </div>
          <div className="ins">
            <p className="text_bold">経過時間</p>
            <p className={`num fontArial ${getProcessBar().overTime && 'red'}`}>
              {getTotalTimeText()[0]}
              <small>:{getTotalTimeText()[1]}</small>
            </p>
          </div>
          <button
            className="btn btn-green"
            onClick={(e) => {
              e.preventDefault();
              props.doneTask();
            }}
          >
            終了
          </button>
        </div>
        <div className="item_process">
          <p>
            目標
            <span className="num fontArial">
              {props.task?.total_time ?? props.task.Task?.time}
            </span>
            分
            {props.task.total_product && (
              <span className="txtsmall">
                （{Math.floor(props.task?.total_time / props.task.total_product)}
                分/個）
              </span>
            )}
          </p>
          <div className={`process ${getProcessBar().overTime && 'overtime'}`}>
            <span style={{ width: getProcessBar().percent + "%" }}></span>
          </div>
        </div>
      </Link>
    </div>
  );
};
