import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";

interface IState {
  modals: any;
  orderGroup: any;
  orderGroups: any;
  orderGroupConflicts: any;
  errors: any;
  filter: any;
  sort: any;
}

const initialState: IState = {
  orderGroups: {
    data: [],
    currentPage: null,
    total: 0,
    lastPage: null,
    itemPerPage: 10,
    isStopLoading: false,
  },
  orderGroup: null,
  orderGroupConflicts: [],
  errors: [],
  filter: {
    createdFrom: [],
    date: null,
    clinicCode: "",
    clinicName: "",
    orderCode: "",
    editorName: "",
  },
  sort: {
    sort_by: "",
    sort_type: "",
  },
  modals: {
    showDetail: false,
    showScan: false,
  },
};

export const orderGroupSlice = createSlice({
  name: "orderGroup",
  initialState,
  reducers: {
    reset(state) {
      state.orderGroups = {...initialState.orderGroups};
      state.orderGroup = { ...initialState.orderGroup };
      state.modals = {
        ...initialState.modals,
      };
    },

    updateSort(state, action) {
      state.sort = action.payload;
    },

    resetFilter(state) {
      state.filter = { ...initialState.filter };
    },

    resetOrderGroup(state) {
      state.orderGroup = {...initialState.orderGroup}
    },

    fetchOrderGroups(state, action) {
      let orderGroups = state.orderGroups;
      let data = action.payload;
      if (data.current_page > orderGroups.currentPage) {
        orderGroups.data = [...orderGroups.data, ...data.data];
      } else {
        orderGroups.data = [...data.data];
      }

      orderGroups.currentPage = data.current_page;
      orderGroups.itemPerPage = data.per_page;
      orderGroups.total = data.total;
      orderGroups.lastPage = data.last_page;
      if (orderGroups.currentPage === data.last_page) {
        orderGroups.isStopLoading = true;
      } else {
        orderGroups.isStopLoading = false;
      }

      state.orderGroups = orderGroups;
    },

    fetchOrderGroup(state, action) {
      state.orderGroup = action.payload;
    },

    updateFilter(state, action) {
      let { key, val } = action.payload;
      let filter = state.filter;

      filter[key] = val;
      state.filter = filter;
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },
    
    fetchOrderGroupConflicts(state, action) {
      state.orderGroupConflicts = action.payload;
      state.modals.showConflict = true;
    },
    
    fetchImportCSVError(state, action) {
      state.errors = action.payload;
      state.modals.showError = true;
    },
  },
  extraReducers: (builder) => {},
});

export default orderGroupSlice;
export const orderGroupReducer = orderGroupSlice.reducer;
export const orderGroupSliceAction = orderGroupSlice.actions;
