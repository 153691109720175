import { createContext, useEffect, useState } from "react"; 
import { imgAssets } from "@/constants/img";
import { modalIds, modalTypes } from "@/constants/modals";
import { useRouter } from "next/router";
import { url } from "@/constants/urlRrefix";
import { getCookie } from "cookies-next";
import { decodeUtf8 } from "@/helpers/encryption";

type Props = {
	children: React.ReactNode;
};
const initialContext = {
	imgAssets,
	modal: {
		modalIds,
		modalTypes
	}
};
export const AssetsContext = createContext(initialContext) as any;
export default function AssetsProvider({ children }: Props) {

	const { asPath, push, pathname } = useRouter()
	
	const [history, setHistory] = useState<string[]>([])

	const [historyLog, setHistoryLog ] = useState<string[]>([])

	const [ historyAsPath, setHistoryAsPath ] = useState<string[]>([])
		

	let ctp5: any = getCookie('ctp5')
    
    let auth: any = ctp5 ? decodeUtf8(ctp5) : {}
	
	const Permission = auth?.Permission || []

	let pathPrev: any = history.filter( (url: any) => url.split("[")[0] !=  pathname.split("[")[0] ) || []
		pathPrev = pathPrev[ pathPrev.length - 1 ]  || ''

	let pathPrevV2: any = historyAsPath.filter( (urlPath: any) => urlPath.split("?")[0] != asPath.split("?")[0] ) || [] 
		pathPrevV2 = pathPrevV2[ pathPrevV2.length - 1 ] || ''
	
	
	

	let isComeBack: boolean = true

	
	historyLog.forEach( (val :any) => {

					if( val.search( pathname ) < 0 )
					{
						isComeBack = false
						return isComeBack
					}
				})

	

	const handleLogUrl = ({ pathname }: any) => {

		setHistoryLog( 
			(historyLog?.length >= 1) ? [ historyLog[historyLog?.length-1], pathname ] 
							  : [ ...historyLog, pathname ]
		 )

		if( pathname == "/" )
	    {
			setHistory([])
			setHistoryAsPath([])
			//setHistoryLog([])
			return true
		}

		

		if( history.find( (res: any) => res.search(pathname) >= 0 ) )
		 	return false;
		
		const data: any = (history?.length > 3 ) ? [ history[history?.length-1], pathname ] : [ ...history, pathname ]
		setHistory(data)
		
		// ** set history with path full
		const dataHPath: any = (historyAsPath?.length > 3) ? [ historyAsPath[historyAsPath?.length-1], asPath ] : [ ...historyAsPath, asPath ]
		setHistoryAsPath(dataHPath)
	} 		

	useEffect(() => { 
		
		handleLogUrl({pathname}) 
		// return () => {}

	}, [asPath])


	let isEditPermission = false;
	let isViewPermission = false;
	let isHiddenPermission = false;
	
	let urlCheck = url.find( (val:any) =>  
								{
									let path = pathname.replace( "/[type]", "" )
														.replace( "/[id]", "" )
														.replace( "/branch", "" )
														.replace( "/implant", "" )
														.replace( "/variations", "" )
														.replace( "/regist", "" )
														.replace( "/create", "" )

														

									if( path.search("/statistic/department") < 0 )
										path = path.replace( "/department", "" )

									if( path.search("/setting/material") >= 0 )
									{
										path = path.replace( "/product", "" )
										path = path.replace( "/metal", "" )										
									}

									if( path.search("/setting/product") >= 0 )
									{
										path = path.replace( "/category", "" )									
									}
									
									return ( val?.url.search( path as string ) >= 0 )
								} 
                         )?.filedName

	let screenPermission = Permission.find( (res:any) => urlCheck == res?.permission )
	
	
	switch(screenPermission?.option)
	{
		case "edit":
			isEditPermission = true;
			break;
		case "view": 
			isViewPermission = true;
	}
	
	
	return (
		<AssetsContext.Provider value={{ 
			pathPrev, 
			pathPrevV2,
			isComeBack, 
			historyAsPath, 
			setHistoryAsPath,
			setHistory,
			asPath,
			pathname,
			history, 
			historyLog,
			Permission,
			screenPermission, 
			isEditPermission,
			isViewPermission,
		}}>
			{children}
		</AssetsContext.Provider>
	)
}