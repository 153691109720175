import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

export interface IStatisticService {
  updatePointData(data: any): Promise<IDataResponse<any>>;
  checkHasUpdateProcess(params: any): Promise<IDataResponse<any>>;
}

@injectable()
export class StatisticService implements IStatisticService {
  private prefix: string;

  constructor() {
    this.prefix = "/labs/statistic";
  }

  async updatePointData(data: any): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/update-point-data`, data);
    return response;
  }

  async checkHasUpdateProcess(params: any): Promise<IDataResponse<any>> {
    const response = await axiosService.get(`${this.prefix}/check-has-update-process`, {
      params: params
    });
    return response;
  }
}
