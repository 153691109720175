import Link from "next/link";
import Image from "next/image";
import { imgAssets } from "@/constants/img";
import React from "react";
import { HookContext, TContextHook } from "@/context/hook";
import { taskSliceAction } from "@/store/task";
import { ModalClinic } from "@/components/order/modals/clinic";
import { container } from "@/di";
import { OrderViewModel } from "@/modelviews/order";
import { PrintHeaderComponent } from "@/components/order/printReceipt/printHeader";
import { useReactToPrint } from "react-to-print";
import { orderSliceAction } from "@/store/order";
import { VisiableMenu } from "@/helpers/mapping";
import { url } from "@/constants/urlRrefix";

export const SideBar = (props: any) => {
  const hooks = React.useContext(HookContext) as TContextHook;
  const orderVM = container.resolve<OrderViewModel>(OrderViewModel);
  orderVM.setHooks(hooks);
  const stateOrder = hooks.useSelector((state: any) => state.order);
  const [checkClinic, setClinic]: any = React.useState([]);
  const profile = hooks.useSelector((state: any) => state.profile);
  const commingSoon = () => alert("Comming soon");
  const printRef = React.useRef(null);
  const handlePrintHeader = useReactToPrint({
    content: () => printRef.current,
  });
  const orderPrints = stateOrder.orderPrints;
  const Permission  = profile?.Permission || [];
  
  const menuStatic = url.filter( (val:any) => val?.type == 'statistic' )

 
  return (
    <>
      {orderPrints.length > 0 &&
          <div style={{display: "none"}}>
            <PrintHeaderComponent  {...{orders: orderPrints, printType: "barcode"}}  ref={printRef} />
          </div>
        }
        {stateOrder.modals.showClinic && <ModalClinic {...props} hiddenOrderFlg={true} handlePrintHeader={handlePrintHeader} ></ModalClinic>}
        <div className="absoluteSide">
          <a
            className="iconclose"
            onClick={() => {
              hooks.dispatch(taskSliceAction.showSidebar(false));
            }}
          >
            <Image unoptimized={true} src={imgAssets.iconRemove} alt="" />
          </a>
          <div className="boxlist">
            <ul>
              <li style={{cursor: "pointer"}}>
                <a 
                  className="btn btn-primary w100pc "
                  onClick={() => {
                    hooks.dispatch(orderSliceAction?.showModal({
                      key:  "showClinic",
                      val: !stateOrder.modals.showClinic
                    }))
                  }}
                >
                  <span className="txt">
                    指示書番号発行
                  </span>
                </a>
              </li>
              
              <li>
                <Link href="order/group" className="btn btn-primary w100pc">
                  <span className="txt">指示書番号登録</span>
                </Link>
              </li>
              <li><Link href="/order/scan"  className="btn btn-primary w100pc"><span className="txt" > レシート印刷 </span></Link></li>
            </ul>
            

              <ul>
              {
                menuStatic.slice( 0, 3 )
                .map( (menu1: any) => 
                (
                  <VisiableMenu { ...{ ...props, Permission } } filedName={menu1?.filedName} key={'mn1'+menu1?.filedName} type="statistic" >
                     <Link href={menu1?.url} className="btn btn-primary w100pc"> <span className="txt" > {menu1?.text} </span> </Link> 
                  </VisiableMenu> 
                ) )
              }
              </ul>
            
            <ul>
            {
                menuStatic.slice( 3 )
                .map( (menu2: any) => 
                (
                  <VisiableMenu { ...{ ...props, Permission } } filedName={menu2?.filedName} key={'mn2'+menu2?.filedName} type="statistic" >
                     <Link href={menu2?.url} className="btn btn-primary w100pc"> <span className="txt" > {menu2?.text} </span> </Link> 
                  </VisiableMenu>
                ) )
              }
            </ul>
            
            <ul>
              <li style={{ backgroundColor: "#6B93BE" }} > 
                <Link style={{ backgroundColor: "#6B93BE" }} 
                      href={ !["hidden"].includes( Permission.find( (res:any) => res.permission == 'lab_setting' )?.option || "hidden" ) ? "setting/labo" : "setting/employee"  } > 
                  <span className="txt">設定</span> 
                </Link> 
              </li>
            </ul>

          </div>
        </div>
    </>
  );
};
