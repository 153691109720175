import { range, validateNumberInt } from "@/helpers";
import { mapOrder } from "@/helpers";
import dayjs from "dayjs";
import { format } from "path";
import { typeSort } from "./form";
import { timeStamp } from "console";

const mapOrderProductDetail = (orderProductDetail: any) => {

  let data: any = [];

    range(1, 32).forEach((toothIndex: any) => {
      let find = orderProductDetail.find( (item: any) => item.ToothIndex?.index === toothIndex );

      if (find)
        data.push({
          switch_type: find.switch_type,
          tooth_index_id: toothIndex,
          milk_teeth_flg: find.milk_teeth_flg,
        });
      else
        data.push({
          switch_type: 0,
          tooth_index_id: toothIndex,
          milk_teeth_flg: false,
        });

    });


    return data;
};

export const mappingOrder = async ( data: any ) => {

  return data.reduce( ( curr :any, acc: any) => {

    return [ ...curr,
              {
                ... mapOrder(acc),
                Process: acc?.Process || [],
              }
          ]

  }, [] )

}

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
      const groupKey = fn(curr);
      const group = prev[groupKey] || [];
      group.push(curr);
      return { ...prev, [groupKey]: group };
  }, {});
}

export const mappingEmployeeWithBranch  = ( data: any , employee: any ) => {
  
  return data.reduce( (cur: any, acc: any ) => {
    
    let itemList: any = { branch_id: acc?.id || null, branch_name: acc?.lab_name || null, department_id: null, department_name: null, employee: [] }

    let curr: any = cur

    const employeeBranch = employee.filter( (emp: any) => emp?.Branch?.id == itemList?.branch_id )

    // ** employee not belong department
    const employeeNotDepart = employeeBranch.filter( (emp: any) => (!emp?.Department?.length) )

    if( employeeNotDepart?.length )
      curr = [ ...curr, { ...itemList, employee: employeeBranch.filter( (emp: any) => (!emp?.Department?.length) ) } ]

    const employeeBelogDepart = acc.Departments.map( (val: any) => 
                                                    ({ ...itemList, 
                                                        department_id: val?.id, 
                                                        department_name: val?.name, 
                                                        employee: employeeBranch.filter( (emp: any) => (  emp?.Department.map( (res:any) => res?.id ).includes( val?.id )  ) )  
                                                    } )
                                                )
                                                .filter( (res: any) => (res?.employee?.length) )

    if( employeeBelogDepart?.length > 0 )                                     
      curr = [ ...curr, ...employeeBelogDepart]
      
      return ( [ ...curr ] )
  }, [] )

}

export const mappingEmployeeFromBranch  = ( data: any , formField: any = {} ) => {

  return data.reduce( (cur: any, acc: any ) => {
    
    let itemList: any = {
                        branch_id: acc?.id || null,
                        branch_name: acc?.lab_name || null,
                        department_id: null,
                        department_name: null,
                        employee: []
                      }

    let curr: any = cur
                      
    if( acc?.UnssignedEmployees?.length )
      curr = [ ...curr, { ...itemList, employee: [
                                                    ...acc?.UnssignedEmployees.filter( (res: any) => 
                                                        (
                                                          ( !formField?.user_roles?.length )
                                                            ? true
                                                            : ( typeof formField?.user_roles == "string"  ? [formField?.user_roles] : formField?.user_roles).includes( res.user_role as string )
                                                        )
                                                    )
                                                 ] 
                        } ]                  
    
    if( acc?.Departments?.length )
      curr = [ ...curr, ...acc.Departments.map( (val: any) => ({ ...itemList, 
                                                                 department_id: val?.id, 
                                                                 department_name: val?.name, 
                                                                 employee: [ 
                                                                              ...val?.Employee.filter( (res: any) => 
                                                                                (
                                                                                    ( !formField?.user_roles?.length )
                                                                                      ? true
                                                                                      : ( typeof formField?.user_roles == "string"  ? [formField?.user_roles] : formField?.user_roles).includes( res.user_role as string )
                                                                                  )
                                                                              )
                                                                           ]  
                                                              }) ) ]

    if( !acc?.UnssignedEmployees?.length && !acc?.Departments?.length )
      curr = [ ...curr, { ...itemList } ]
  
   
      return ( [ ...curr ] )
  }, [] )

}

export const getEmployeeFromBranch  = ( data: any ) => {

  return data.reduce( (cur: any, acc: any ) =>
  {
    let curr = cur 

    if( acc?.UnssignedEmployees?.length )
      curr = [ ...cur, ...acc?.UnssignedEmployees ];

    return (!acc.Departments || ! acc.Departments.length) ?
                                  ([ ...curr ])
                               :
                                  ([ ...curr, ...acc.Departments.reduce( (curEmp: any, accEmp: any) =>  [ ...curEmp,...accEmp?.Employee ], [] ) ])
  }

    , [] )

}


export const checkWarningTask  = ( task : any, callBack: Function ) : { className : any, onClick: any | null } => {
  
  const taskWarning: any = task.filter( (items: any, ind: number) => ( (items?.status == 'completed') && (!items?.working_time || (Number( items?.working_time ) <= 0 )) ) )
                               .map( (val: any) => ({ task_id: val?.id, processCategory: val?.Task?.ProcessCategory,
                                                      name: val?.Task?.name,
                                                      ...val.Task
                                                      //ProcessCategory: val?.Task?.ProcessCategory
                                                    }) )

  return taskWarning?.length ? ({ className: "hasNotice", onClick: (e:any) => { callBack(taskWarning) }  }) : ({ className: "", onClick: null })

}

export const getStatusTimeLineTask  = ( totalTime: Number, workingTime: Number, status: any ) => {
  
  
  if( (workingTime > totalTime) && (status == 'completed')  ) 
    return {  background: "#ff3b30", color: "white" }

  if( status == 'pause' ) //not_finished
    return {  background: "#f79f1f", color: "white" }

  if( status == 'completed' ) //Finished
    return {  background: "#7ab57e", color: "white" }

  if( status == 'doing' ) //in_progress
    return {  background: "#518ced", color: "white" }

}

export const getTimeLineTask  = ( task : any , limit: number = 30) => {

  const checkDulicate = task.reduce( (cur:any, res: any) => 
                                ([ 
                                  ...cur, 
                                   ...( ( res?.UserProcessLog || [] ) .map( (resL: any) => ({ start_time: dayjs(resL?.start_time),  end_time: dayjs(resL?.end_time), Task:  res?.Task || {} })  ) )
                                ])
                            , [])
  
  return task.reduce( (acc: any, cur:any) => {

      const { total_time, working_time, status, user_id, UserProcessLog, id, Task  } = cur
      
      if( !UserProcessLog || !UserProcessLog.length )
        return [ ...acc ]

      //const workingCur: any = UserProcessLog.reduce( (cur: number, acc: any) => cur + Number( acc?.time_gap), 0 ) 

      let accumWorkingTask : number = 0;
      
      let item: any = UserProcessLog.map( (val:any, index: number) => 
      {
                                      
          accumWorkingTask += val?.grap_flag

      // ** check dulicate
     
      let chec =  checkDulicate.find( (res:any)=> ( ( res.start_time.valueOf() < dayjs( val?.end_time ).valueOf() ) && ( res.end_time.valueOf() > dayjs( val?.end_time ).valueOf() ) ) )
      
      if( chec?.start_time )
        val = { ...val, end_time: dayjs( chec?.start_time ).valueOf() }

      // const timeGap =  val?.end_time ? val?.time_gap : dayjs().diff( dayjs( val?.start_time ), "minute" )

                                      return (
                                        {
                                          itemProps: { style: getStatusTimeLineTask( parseFloat(total_time), Number( working_time / 60 ), status) },
                                          status,
                                          group: user_id,  
                                          title: Task?.name || "",
                                          content: (<>
                                                  {
                                                    Number(val?.time_gap) > 1 && (
                                                      <div className="inside">
                                                        <p className="titleTop">{ Task?.name || '' }</p>
                                                        <p>{ (Number(val?.time_gap) >= limit) && (<>目標 </>) }<span>{ Math.round(total_time) }</span>分</p>
                                                        <p><span>{val?.time_gap}</span>分 { ( (Number(val?.time_gap) >= limit) && (index>0)) && (<>合計<span>{ validateNumberInt( Number(accumWorkingTask/60) ) }</span>分</>)  } </p>
                                                      </div>
                                                    )
                                                  }
                                                  </>),
                                          start_time: new Date(val?.start_time),
                                          end_time: val?.end_time ? new Date(val?.end_time) : new Date(),
                                          totalTimeLog: Number(val?.time_gap),
                                          task_id: id,
                                          id: val?.id
                                        }
                                      )
                                    }
                                    )
                                    
    return [ ...acc, ...item ]
  }, [] )

}


export const getStatusActualHoursTask  = ( task: any ) => {

  const { total_time, working_time} = task

  if( (Number(working_time) > Number(total_time) )  )
    return {  background: "#ff3b30", color: "white" }

    return {  background: "#51c17d", color: "white" }

}

export const toHoursAndMinutes = (totalMinutes : any, isConverString: boolean = false) => {

  let hours: any = Math.floor(totalMinutes / 60);
  let minutes: any = totalMinutes % 60;

  if(isConverString)
  {
    if( totalMinutes < 1 )
    return { hours: "-", minutes: "-", total: totalMinutes };
    
    minutes = minutes.toString().padStart(2, "0")
  }
    
  return { hours: (hours <= 0) ? "" : hours , minutes, total: totalMinutes };
}

export const getActualHoursTask  = ( task : any , object: any ) => {
      
      const { year, month, day, unitOneHour, data: { target_working_day, total_working_day }, isAvg , intChange } = object
      const date:any = `${object.year}-${object.month}-${object.day}`
      
      const limitShow = (()=>{
                            if( intChange <= 1 )
                              return 5

                            if( intChange <= 3 )
                              return 10

                            if( intChange <= 9 )
                              return 40
                              return 50
                        })()

      const nowDate = dayjs( new Date(date) ).startOf("day").valueOf()

      return task.reduce( (acc: any, val:any) =>

                    {
                      
                      
                        let workingTime:any = isAvg ? ( val?.working_time / total_working_day ) : val?.working_time
                        let Targetime:any = isAvg ? val?.total_time / target_working_day : val?.total_time
                        const index = acc?.length

                        let betweenTime: any =   Number( workingTime / unitOneHour ) || 0
                            // betweenTime = betweenTime > 0 ? betweenTime : 1

                        let start:  any = !index  ? nowDate : acc[index-1]?.end
                        let end:  any = !index  ? dayjs(nowDate) : dayjs(acc[index-1]?.end)
                        
                        if( betweenTime )
                          end = end.add( betweenTime, "minutes" ).valueOf()

                        const { hours, minutes } = toHoursAndMinutes(  validateNumberInt(workingTime)  )
                        

                          return ([ ...acc, {
                                      itemProps: { style: getStatusActualHoursTask(val) },
                                      group: val?.user_id,
                                      title: val?.Task?.name || '' ,
                                      content: (<> {
                                                  <div className={`inside ${(Number(val?.working_time) < 10) && "eSmall"}`}>
                                                    <p className="titleTop">{ val?.Task?.name || '' }</p>
                                                    <p>{ (Number(val?.working_time) > limitShow) && (<>目標 </>) }<span>{ Math.floor(Targetime )  }</span>分</p>
                                                    <p>{ (hours > 0) && (<><span>{hours}</span>時間</>) } <span>{minutes}</span>分</p>
                                                  </div>
                                              } </>),
                                      start,
                                      end,
                                      start_time: dayjs(start),
                                      end_time:  dayjs(end),//new Date(`${date} ${end?.hours}:${end?.minutes}`) ,
                                      totalTime: betweenTime,
                                      id: val?.id,
                                  }])

                  }, [] )

}
 
export const VisiableMenu = ( { children, filedName, ...props }: any ) => {
 
 const res = props?.Permission.find( (val:any) => val?.permission == filedName ) 
 
 if( !res?.permission )
  return (<></>)
 
 if( ( props?.type == 'statistic' ) )    
    return ['hidden', 'disable'].includes(res?.option) ? (<li className="liDisabled" >{ children }</li>) : (<li>{ children }</li>)
  
 return  ['hidden'].includes(res?.option) ? (<></>) : (<>{ children }</>) 
}       
