import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import { useCategories } from "@/components/mixins/order/process/useCategories";

export const useFavorite = ( type: any ) => {

  const { categoriesFavorite, fetchCategories, handleFavoriteProcess } = useCategories( null, false, null, null, true )
  const hooks = React.useContext(HookContext) as TContextHook;

  //const categories  = hooks.useSelector((state: any) => state.global?.categoriesFv) || [];


  const addFavorit =  (data : any) => {
    handleFavoriteProcess(data)
    return true;
  }

  React.useEffect( () => {
    int()
  }, [type])

  const int = () => {
    fetchCategories(type)
  }

  return { addFavorit, categories: categoriesFavorite }
};
