import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";

import { BaseViewModel } from "@/modelviews/BaseViewModel";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { setDepartmentList } from "@/store/setting/department";
import { AssetsContext } from "@/context/theme";
import React from "react";

@injectable()
export class SettingDepartmentViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.DepartmentRepository)
    private settingDepartmentRepo: any,
    @inject(TYPES.DepartmentRepository) 
    private permission: any
  ) {
    super();
    const res = React.useContext(AssetsContext) as any;
    this.permission = res?.screenPermission   
  }
  async getDepartmentList(labId: any): Promise<IDataResponse<any>> {
    
    let param: any = { limit: -1 }

    if(  ['branch', 'department', 'only_me' ].includes( this.permission?.option as string ) )
      param = { ...param, screen: this.permission?.permission }

    let res = await this.settingDepartmentRepo.fetchs(param);
    if (res.success && res.data) {
      this.hooks.dispatch(setDepartmentList(res.data));
    } else {
      this.setMsg(res?.message, -1);
    }
    return res;
  }

}