import axiosService from "@services/axiosService";
import {
  IUserCredentials,
  IUserQRCredentials,
  IAuthToken,
  IUserUpdate,
  Profile,
} from "@models/auth";
import { IAuthService } from "@services/interface/IAuthService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";

@injectable()
export class AuthService implements IAuthService {
  private prefix: string;
  constructor() {
    this.prefix = "/auth";
  }

  async forgotPassword(params: any): Promise<IDataResponse<any>> {
    return await axiosService.post( `${this.prefix}/forgot-password`, params );
  }

  async resetPassword(params: any): Promise<IDataResponse<any>> {
    return await axiosService.post( `${this.prefix}/reset-password`, params );
  }

  async login(useCredentials: IUserCredentials): Promise<IDataResponse<any>> {
    const response = await axiosService.post(
      `${this.prefix}/login`,
      useCredentials
    );
    return response;
  }

  async logOut( ): Promise<IDataResponse<any>> {
    return await axiosService.get( `${this.prefix}/logout` );
  }

  async loginByQR(data: IUserQRCredentials): Promise<IDataResponse<any>> {
    const response = await axiosService.post(`${this.prefix}/loginQR`, data);
    return response;
  }

  async getProfile( params: any = `?includes=Lab&includes=Permission` ): Promise<IDataResponse<Profile>> {
    const response = await axiosService.get(`/profile/me${params}`);
    return response;
  }

  async updateProfile(data: IUserUpdate): Promise<IDataResponse<Profile>> {
    const response = await axiosService.put(`/profile/me`, data);
    return response;
  }

  async withdrawProfile(data: IUserUpdate): Promise<IDataResponse<Profile>> {
    return await axiosService.post(`${this.prefix}/withdraw`);
  }
}
