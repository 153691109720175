import { convertTzTo } from "@/helpers";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _, { find } from "lodash";
import moment from "moment";

interface IState {
  modals: any;
  orderProgressSelected: any;
  orderProgresses: Array<any>;
  orderList: any;
  orderDetail: any;
  orderLogChange: Array<any>;
  linkReverPage: any;
  tempProduct: any;
  tempSelectedSwitchProducts: any;
  overdateOrder: any;
  selectStatus: any; // remake, try_on, after_trial
  process: any;
  orderPrints: any;
  scanCode: any;
  instructionStatusFlow: any;
  overDateScanFlow: any;
  removedOrderParentIds: any;
  retryProductBridgeIndex: any;
  addProductFlg: Boolean; 
}

const initialState: IState = {
  scanCode: null,
  orderPrints: [],
  orderProgresses: [],
  orderProgressSelected: null,
  overdateOrder: null,
  selectStatus: null,
  // quy trinh
  process: null,

  removedOrderParentIds: [],
  orderList: {
    data: [],
    currentPage: null,
    total: 0,
    lastPage: null,
    itemPerPage: 10,
    isStopLoading: false,
    filter: {
      isDefaultStatus: false,
      keyword: "",
      classes: [],
      instructionStatus: [],
      startDate: null,
      endDate: null,
    },
  },
  orderDetail: null,
  tempProduct: null,
  tempSelectedSwitchProducts: [],
  orderLogChange: [],
  linkReverPage: "",
  modals: {
    showClinic: false,
    showSwitchProduct: false,
    showScan: false,
    showMaterial: false,
    showManufactory: false,
    showConfirmOrderDetail: false,
    showConfirmOrder: false,
    showChooseTeeth: false,
    showDeleteMaterial: false,
    showDeleteSwitchProduct: false,
    showDeleteProduct: false,
    showConfirmReverPage: false,
    showVariationProduct: false,
    showTrialConfirm: false,
    showConfirmOrderDelete: false,
    showChooseTeethSwitch: false,
    showAlertProductIncludeProcess: false,
    showAlertOrderNotExist: false,
    showAlertOrderDuplicateScan: false,
    showAlertInputOrderCode: false,
    showDeleteSwichProductMaterial: false,
  },

  overDateScanFlow: {
    showOverdateConfirmScan: false,
    showOverdateFinishScan: false,
  },

  instructionStatusFlow: {
    showOrderList: false,
    showOrderDetail: false,
    showInstructionOrderScan: false,
  },
  retryProductBridgeIndex: null,
  addProductFlg: false
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    reset(state) {
      state.orderProgresses = [...initialState.orderProgresses];
      state.orderProgressSelected = null;
      state.modals = {
        ...initialState.modals,
      };
    },

    resetAlertModals(state) {
      state.modals.showAlertProductIncludeProcess = false;
      state.modals.showAlertOrderDuplicateScan = false;
      state.modals.showAlertOrderNotExist = false;
      state.modals.showAlertInputOrderCode = false;
      state.overDateScanFlow.showOverdateConfirmScan = false;
    },
    
    setRetryProductBridgeIndex(state, action)
    {
      state.retryProductBridgeIndex = action.payload
    },

    setRemovedOrderParentIds (state, action)  {
      state.removedOrderParentIds = [...action.payload]
    },

    setOrderProgresses (state, action) {
      state.orderProgresses = [...action.payload]
    },

    setScanCode(state, action) {
      state.scanCode = action.payload
    },

    // payload:  orders
    setOrderPrints(state, action) {
      state.orderPrints = [...action.payload]
    },

    setLinkReverPage(state, action) {
      const { value, request } = action.payload;
      state.linkReverPage = value;
    },

    addOrderLog(state, action) {
      const {
        key,
        event,
        request,
      }: {
        key: "patientName" | "appointmentDate" | "images" | "product" | string;
        event: any;
        request: any;
      } = action.payload;

      const logChange = state.orderLogChange;
      switch (request) {
        case "rest":
          state.orderLogChange = [];
          break;
        default:
          if (!logChange.includes(key as string))
            state.orderLogChange = [...logChange, key];
      }
    },

    setProcess(state, action) {
      state.process = action.payload
    },

    setSelectStatus(state, action) {
      state.selectStatus = action.payload;
    },

    setOverdateOrder(state, action) {
      state.overdateOrder = action.payload;
    },

    removeOverdateOrder(state, action) {
      state.overdateOrder = null;
    },

    saveTempSelectedSwitchProduct(state, action) {
      state.tempSelectedSwitchProducts = action.payload;
    },

    removeTempSelectedSwitchProduct(state) {
      state.tempSelectedSwitchProducts = [];
    },

    saveTempProduct(state, action) {
      state.tempProduct = action.payload;
    },

    removeTempProduct(state) {
      state.tempProduct = null;
    },

    fetchOrderList(state, action) {
      let orderList = state.orderList;
      let data = action.payload;
      if (data.current_page > orderList.currentPage) {
        orderList.data = [...orderList.data, ...data.data];
      } else if(data.current_page === 1) {
        orderList.data = [...data.data];
      }

      orderList.currentPage = data.current_page;
      orderList.itemPerPage = data.per_page;
      orderList.total = data.total;
      orderList.lastPage = data.last_page;
      if (orderList.currentPage === data.last_page) {
        orderList.isStopLoading = true;
      } else {
        orderList.isStopLoading = false;
      }

      state.orderList = orderList;
    },

    sortOrderList(state, action) {
      let {data} = action.payload
      let orderList = state.orderList
      orderList.data = [...data]
      state.orderList  = orderList
    },

    setFilterOrderList(state, action) {
      let filterOrderList = { ...state.orderList.filter };
      let { key, value } = action.payload;
      filterOrderList[key] = value;
      state.orderList.filter = filterOrderList;
    },

    fetchOrderDetail(state, action) {
      state.orderDetail = action.payload;
    },
    removeOrder(state, action) {
      let orderId = action.payload;
      let orderProgresses = state.orderProgresses;
      let orderProgressSelected = state.orderProgressSelected;
      let findIndex = orderProgresses.findIndex(
        (order: any) => order.id === orderId
      );
      if (findIndex !== -1) {
        if (orderProgressSelected && orderProgressSelected.id === orderId) {
          state.orderProgressSelected = null;
        }
        // jump next order
        let nextIndex = findIndex + 1;
        if (typeof orderProgresses[nextIndex] !== "undefined") {
          orderProgressSelected = { ...orderProgresses[nextIndex] };
          state.orderProgressSelected = orderProgressSelected;
        } else {
          // when remove last
          if (
            findIndex === orderProgresses.length - 1 &&
            orderProgresses.length > 1
          ) {
            orderProgressSelected = {
              ...orderProgresses[orderProgresses.length - 2],
            };
            state.orderProgressSelected = orderProgressSelected;
          }
        }
        orderProgresses.splice(findIndex, 1);
      }

      // remove order log if not exist orders
      if(orderProgresses.length <= 0)
      {
        state.orderLogChange = [];
      }

      state.orderProgresses = [...orderProgresses];
    },

    addOrder(state, action) {
      let data = action.payload;
      let orderProgresses = state.orderProgresses;
      let orderProgressSelected = state.orderProgressSelected;
      let newOrder = {
        ...data,
        id: data.id,
        orderCode: data.orderCode,
        clinic: data.clinic,
        patientName: data.patientName,
        appointmentDate: data.appointmentDate,
        appointmentTime: data.appointmentTime,
        hasChange: data.hasChange,
        instructionStatus: data.instructionStatus,
        orderProducts: data.orderProducts.map((orderProduct: any, index: number) => ({
          ...orderProduct,
          index: index
        })),
        isUsed: true,
        orderParents: data.orderParents,
      };
      orderProgresses.unshift(newOrder);
      if (orderProgresses.length > 0) {
        orderProgressSelected = {...orderProgresses[0]}
      }
      state.orderProgressSelected = { ...orderProgressSelected };
      state.orderProgresses = [...orderProgresses];
    },

    // phuong custom store add many order
    addOrders(state, action) {   
      state.orderProgressSelected = { ... action.payload[0]  }   
      state.orderProgresses = [ ...action.payload ]
    },

    selectOrderProgress(state, action) {
      let { index, data } = action.payload;
      let orderProgresses = state.orderProgresses;
      let orderProgressSelected = { ...data };

      // isUsed:  no need call api when active
      orderProgressSelected.isUsed = true;
      orderProgresses[index] = orderProgressSelected;
      state.orderProgressSelected = orderProgressSelected;
      state.orderProgresses = orderProgresses
    },

    selectOrderCustom(state, action ) {
      let { data } = action.payload;
      state.orderProgressSelected = data;
    },



    updateOrderProgress(state, action) {
      let { key, val } = action.payload;
      let orderProgresses = state.orderProgresses;
      let orderProgressSelected = state.orderProgressSelected;
      let findIndex = orderProgresses.findIndex(
        (item: any) => item.id === orderProgressSelected.id
      );
      /* orderProducts: [
        {
          materials : [],
          orderProductDetails: [],
          switchProducts: []
        }
      ]
      */

      if (key === "orderProducts" || key === "orderParents") {
        let orderProducts = orderProgressSelected[key];

        // add product observer
        if(orderProducts.length > 0 &&  val.length > orderProducts.length )
        {
          state.addProductFlg = true;
        }else {
          state.addProductFlg = false;
        }

        orderProducts = [...val];
      
        // map index
        orderProducts = orderProducts.map((orderProduct: any, index: number) => ({
          ...orderProduct,
          index: index
        }))
        orderProgressSelected[key] = orderProducts;
      } else {
        orderProgressSelected[key] = val;
      }

      // add event change PO
      if(key !== "LastConfirmStaff")
      {
        orderProgressSelected.hasChange = true;
      }

      state.orderProgressSelected = orderProgressSelected;
      if (findIndex !== -1) {
        if (key === "appointmentDate" && !val) {
          orderProgressSelected["appointmentTime"] = null;
        }

        orderProgressSelected["patientName"] = (orderProgressSelected.patientNameSei || orderProgressSelected.patientNameMei)
         ? `${orderProgressSelected?.patientNameSei || ""} ${orderProgressSelected.patientNameMei || ""}` : ""
        
        // handle log change product
        if (["orderProducts"].includes(key)
        ) {
          const logChange: any = state.orderLogChange;
          if (!logChange.includes("product")) {
            state.orderLogChange = [...logChange, "product"];
          }
        }

        // handle set overtime
        if(["appointmentDate", "appointmentTime"].includes(key))
        {
          let appointmentDate = convertTzTo(orderProgressSelected["appointmentDate"], false, "-");
          let appointmentTime = orderProgressSelected["appointmentTime"]

          let now = moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss");
          let appointment = moment(new Date(`${appointmentDate} ${appointmentTime || ""}`)).format("YYYY-MM-DD HH:mm:ss")
          if(now > appointment)
          {
            orderProgressSelected["is_over_time"] = true
          }else {
            orderProgressSelected["is_over_time"] = false
          }
        }

        // handle update order
        orderProgresses[findIndex] = orderProgressSelected;
        state.orderProgresses = [...orderProgresses];
      }
    },

    resetOrderList(state, action) {
      state.orderList = { ...initialState.orderList };
    },

    resetOrderListData(state) {
      state.orderList.data = [...initialState.orderList.data ];
    },

    showInstructionFlowModal(state, action) {
      let instructionStatusFlow = {...state.instructionStatusFlow}
      let data = action.payload;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        

        if(key === "showInstructionOrderScan")
        {
          instructionStatusFlow["showOrderList"] = true;
        }else {
          for (const property in instructionStatusFlow) {
            instructionStatusFlow[property] = false;
          }
        }
      }
      instructionStatusFlow[key] = val;
      state.instructionStatusFlow = instructionStatusFlow;
    },

    showOverdateScanModal(state, action) {
      let data = action.payload;
      let overDateScanFlow = state.overDateScanFlow;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in overDateScanFlow) {
          overDateScanFlow[property] = false;
        }
      }
      overDateScanFlow[key] = val;
      state.overDateScanFlow = overDateScanFlow;
    },

    showModal(state, action) {
      let data = action.payload;
      let modals = state.modals;
      const { key, val } = data;

      // reset all modal
      if (val === true) {
        for (const property in modals) {
          modals[property] = false;
        }
      }
      modals[key] = val;
      state.modals = modals;
    },


  },
  extraReducers: (builder) => {},
});

export default orderSlice;
export const orderReducer = orderSlice.reducer;
export const orderSliceAction = orderSlice.actions;
