import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEmployee, IEmployeeUpdate } from "@/models/setting/Employee";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
import { IUserRole } from "@/models/User";
import { baseUtils } from "@/utils/base";
import { IPagination } from "@/models/Pagination";
import { paginationDefault } from "@/constants/pagination";

interface IEmployeeState {
  currentEmployee: IEmployee;
  formField: { [ key: string ] : any };
  validateField: any;
  branchList: any[];
  departmentList: any[];
  employeeList: {
    data: IEmployee[];
    pagination: IPagination;
  }
  isRetired: any;
}
const initialState: IEmployeeState = {
  currentEmployee: {} as IEmployee,
  formField: {
    name_sei: "",
    name_mei: "",
    email: "",
    employee_barcode: "",
    qr_token: "",
    target_point: "",
    password: "",
    branch_id: "",
    department_ids: [],
    user_role: "",
    // permission_ids: [],
    permissions: []
  },
  branchList: [],
  departmentList: [],
  validateField: {},
  employeeList: {
    data: [],
    pagination: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      from: 0,
      to: 0,
    }
  },
  isRetired: null,
};

export const settingEmployeeSlice = createSlice({
  name: "settingEmployee",
  initialState,
  reducers: {
    resetFormField(state) {
      state.formField = initialState.formField;
      state.validateField = {}
    },
    setFormField(
      state,
      action: PayloadAction<{
        value: any;
        name: any;
        type?: string;
        notRequired?: any | null
      }>
    ) {
      const { value, name, type, notRequired } = action.payload;

      switch(name)
      {
        case 'user_role':
              state.formField[name] = value as IUserRole; break;
        case 'permissions':
              state.formField[name] = value; break; 
        case 'department_ids':
              ( typeof value == 'object' ) ?  ( state.formField[name] = value )
                                           :  (
                                                (state.formField[name].includes(value as string)) 
                                                      ? ( state.formField[name] = state.formField[name].filter( (item: string) => item !== value ) )
                                                      : ( state.formField[name].push(value as string) )
                                              )
              break;
        default:  
              state.formField[name] = value as string; break;                              
      }  

      if ( (type == "change") && (name == "password") )
      {
        let text:string = state.formField[name] as string

        if( text.indexOf("*******") >=0 )
            state.formField[name] = text.replaceAll("*","")

      }



      // clear department when change branch
      if( name == 'branch_id' )
      {
        state.formField['department_ids'] = []
        state.validateField['department_ids'] = {}
      }

      const _validation = new FormValidation()

      if (!notRequired.includes(name as string))
        _validation.required( value, fieldName[name] as string );

      if (name == "employee_barcode")
        _validation.maxLength(value , 20)
        
      if (name == "email")
        _validation.emailValid(value);        

       if (name == "password")
         _validation.passwordValid(value);

        if (name == "target_point")
        _validation.float(value, fieldName[name] as string);

        state.validateField[name] = _validation?.respone

        // check more request when exit email must be request pass, and else

        if( ['email', 'password'].includes(name as string) )
        {
          let _check:string  = name == "email" ? "password" : "email";
          let isCheck: any = notRequired.filter( (val:string) => ['email', 'password'].includes(val as string) )
          
          state.validateField[_check] = (isCheck?.length < 2 ) 
                                        ? 
                                          (
                                            state.validateField[_check]?.isValid 
                                              ? new FormValidation().required( state.formField[_check], fieldName[_check] as string )
                                              : { ...state.validateField[_check] }
                                          )
                                        : 
                                        {isValid: true, message: '' }
          
          
        }

    },

    setCurrentEmployee(state, action: PayloadAction<IEmployee>) {
      state.currentEmployee = action.payload;
      const {retired_flg, retired_at} = state.currentEmployee;
      state.isRetired = {retired_flg, retired_at}
      const {
        name_sei = "",
        name_mei = "",
        email = "",
        target_point = "",
        branch_id = "",
        user_role = "",
        Department = [],
        UserPermissionOption = [],
        password_length = 0,
        employee_barcode = "",
        qr_token = ""
      } = {
        ...state.currentEmployee,
        name_sei: state.currentEmployee.name_sei || "",
        name_mei: state.currentEmployee.name_mei || "",
        email: state.currentEmployee.email || "",
        target_point: state.currentEmployee.target_point || "",
        branch_id: state.currentEmployee.Branch?.id || "",
        user_role: state.currentEmployee.user_role || "",
        password_length: state.currentEmployee.password_length || 0,
        employee_barcode: state.currentEmployee.employee_barcode || "",
        qr_token: state.currentEmployee.qr_token || "",
      };

      
      const password = password_length != 0 ? baseUtils.changePassLengthToSpace(password_length) : "";


      const department_ids = Department.reduce((acc, cur) => {

        if( cur?.Branch.map( (vaBr : any) => Number( vaBr?.id ) ).includes( branch_id ) )
          return [...acc, cur.id.toString()];

          return [...acc];
      }, []);
       
      const permissions = UserPermissionOption.reduce((acc : any, cur : any) => ([...acc, { permission_id: cur?.permission_id, option_id: cur?.option_id } ]), []);
      

      state.formField = {
        name_sei,
        name_mei,
        email,
        employee_barcode,
        qr_token,
        target_point,
        password,
        branch_id,
        department_ids,
        user_role,
        permissions,
      };

      state.validateField = {
        
        name_sei: new FormValidation().required( state.currentEmployee.name_sei || "", fieldName.name_sei ),
        name_mei: new FormValidation().required( state.currentEmployee.name_mei || "", fieldName.name_mei ),
        email: new FormValidation().emailValid(state.currentEmployee.email),
        
        // employee_barcode: new FormValidation().required( state.currentEmployee.employee_barcode || "", fieldName.employee_barcode ),

        target_point: new FormValidation().required( state.currentEmployee.target_point || "", fieldName.target_point ),

        password: new FormValidation().passwordValid(password),

        branch_id: new FormValidation().required( state.currentEmployee.Branch?.id || "", fieldName.branch_id ),

        //department_ids: new FormValidation().required( state.currentEmployee.Department.length == 0 ? "" : "a", fieldName.department_ids ),

        user_role: new FormValidation().required( state.currentEmployee.user_role || "", fieldName.user_role ),

        // permission_ids: new FormValidation().required( state.currentEmployee.Permission.length == 0 ? "" : "a", fieldName.permission_ids ),

      };

      const flagDS = [ { email } , { password } ].reduce( (acc:any, cur:any) => {

                        const keys: any = Object.keys(cur)
                        
                        if( !( cur[ keys as string ] ) )
                          return ( [ ...acc, keys[0] ] )

                        return ( [ ...acc ] )
                    }, [] )
      
      if( flagDS?.length == 1 )
        state.validateField[ flagDS[0] as string ] = new FormValidation().required( "", fieldName[ flagDS[0] ] as string )
                                    

    },

    setEmployeeList(state, action: PayloadAction<{
      data: IEmployee[];
      pagination: IPagination;
    }>) {
      state.employeeList = action.payload;
      state.employeeList.pagination = {
        ...state.employeeList.pagination,
        per_page: paginationDefault.per_page || 10,
      }
    },
    resetEmployeeList(state) {
      state.employeeList = initialState?.employeeList
    }
  },
});

const employeeReducer = settingEmployeeSlice.reducer;
const { setFormField, setCurrentEmployee, resetFormField, setEmployeeList, resetEmployeeList} =
  settingEmployeeSlice.actions;
export { employeeReducer, setCurrentEmployee, setFormField, resetFormField, setEmployeeList , resetEmployeeList};
