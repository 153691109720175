import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import Image from "next/image";
import { imgAssets } from "@/constants/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { ModalComfirm } from "@/components/modals/setting/labo/modalCustum";
import { useFavorite } from "./useFavorite";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { setModal } from "@/store/global";
import _ from "lodash";

export const ModalFavorite: React.FC<any> = ({
  CustomNav,
  CustomNavLink,
  tapPane,
  userInfo,
  onSubmit,
  ...props
}) => {
  let [selectedProcess, setSelectedProcess]: any = React.useState([]);
  const [type, setType]: any = React.useState("common");
  let [currentCategory, setCurrentCategory]: any = React.useState(null);
  let selectedProcessIds = selectedProcess.map((item: any) => item.id)

  const { addFavorit, categories } = useFavorite(undefined);
  let [categoriesByType, setCategoriesByType] = React.useState([]);

  React.useEffect(() => {
    if (categories.length > 0) {
      let getCatgories = categories.filter((category: any) => category.type === type);
      setCategoriesByType(getCatgories)
      setCurrentCategory(getCatgories[0]);
    }
  }, [type, categories]);

  
  React.useEffect(() => {
    
    let favProcesses = categories
    .map((category: any) => category.Process)
    .flat()
    .filter((process: any) => process.FavProcess.length > 0)

    setSelectedProcess(favProcesses);
  }, [categories])

  React.useEffect(() => {
    return () => {
      setSelectedProcess([])
    }
  }, [])


  const handleSubmit = async (event: any) => {
    let data : any = Object.values(selectedProcess.reduce((acc: any, { category_id, ...process } : any) => {
      if(!acc[category_id])
      {
        let processIds = [];
        processIds.push(process.id)
        acc[category_id] = {
          process_category_id: category_id,
          process_ids: processIds
        }
      }else {
        let getItem : any = {...acc[category_id]}
        let processIds = getItem["process_ids"];
        processIds.push(process.id)
        getItem  = {
          ...getItem,
          process_ids: processIds
        }
       
        acc[category_id] = getItem
      }
      return acc;

    }, {}))

    await addFavorit(data);
    return onSubmit(event);
  };
  const handleSelectFavorite = (event: any, process: any) => {
    const { value, checked } = event.target;
    let getSelectedProcess = [...selectedProcess];
    let findItem = getSelectedProcess.find((item: any) => item.id === process.id)

    if(findItem)
    {
      getSelectedProcess = getSelectedProcess.filter((item: any) => item.id !== process.id)
    }else { 
      getSelectedProcess.push(process);
    }

    setSelectedProcess(getSelectedProcess);
  };

  const handleUnSelectedProcess = () => {
    let currentSelectedProcesses =   categoriesByType.map((category: any) => category.Process)
                                                    .flat()
                                                    .filter((item: any) => selectedProcessIds.includes(item.id))
    let currentSelectedProcessIds : any = currentSelectedProcesses.map((item: any)  => item.id)
    selectedProcess = selectedProcess.filter((item: any) => !currentSelectedProcessIds.includes(item.id)) 
    setSelectedProcess(selectedProcess);
  };

  const getSelectedProcessOfCategory = (category: any) => {
    if (!category) {
      return [];
    }

    let categoryProcessIds = category.Process.map(
      (process: any) => process.id
    );
    
    return selectedProcessIds.filter((processId: number) =>
      categoryProcessIds.includes(processId)
    );
  };


  return (
    <ModalComfirm modalId="Selection" modalType="custom">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title text_center">
            お気に入り工程編集
            <span className="ico ico-q">
              <OverlayTrigger
                placement="right-start"
                overlay={
                  <Tooltip id="tooltip-2">
                    {
                      <>
                        「製作工程 架工」といった分類 <br />{" "}
                        ごとに最大10工程をお気に入りと <br /> して登録できます。{" "}
                        <br /> お気に入りの工程は、上部に固定 <br />{" "}
                        表示されます。
                      </>
                    }{" "}
                  </Tooltip>
                }
              >
                <span className="ico_tooltip cursorPointer">
                  <Image
                    className="mr15"
                    src={imgAssets.iconQuestion}
                    alt="iconQuestion"
                  />
                </span>
              </OverlayTrigger>
            </span>
          </h4>
          <button
            className="close"
            type="button"
            data-request="close"
            data-modal="Selection"
            onClick={(e: any) => {
              props?.hooks.dispatch(setModal({ isCloseAll: true }));
            }}
          >
            <Image src={props.imgAssets.iconClose} alt="iconClose"></Image>
          </button>
        </div>
        <div className="blockfavoriteProcess">
          <ul className="dflex">
            {[
              { name: "共通工程", id: "common" },
              { name: "製作工程", id: "product" },
            ].map((val: any, index: number) => (
              <li
                key={"tpy" + index}
                className={type == val?.id ? "-active" : ""}
              >
                <button
                  data-value={val?.id}
                  onClick={() => setType(val?.id)}
                  // disabled={ val?.id == "product" && userInfo?.user_role == "lab_app" }
                >
                  {val?.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="modal-body" id="modalFavorites">
          {categories.length > 0 && (
            <div className="blockDetail listFavorites">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={currentCategory?.id}
                activeKey={currentCategory?.id}
                onSelect={(e: any) =>
                  setCurrentCategory(
                    categories.find((item: any) => item.id == e)
                  )
                }
              >
                <Nav variant="pills" className="listul_l" as={CustomNav}>
                  {categoriesByType.map((val: any, index: number) => (
                    <Nav.Link
                      key={val?.id + "ind" + index}
                      eventKey={val.id}
                      as={CustomNavLink}
                      bsPrefix="tab-container"
                    >
                      {val?.name}
                    </Nav.Link>
                  ))}
                </Nav>

                <div className="p15">
                  {categoriesByType.length >  0 && categoriesByType.map((val: any, index: number) => (
                    <Tab.Pane
                      eventKey={val.id}
                      as={tapPane}
                      bsPrefix="listproducts listproducts-noimg listproducts-process"
                      key={val?.id + "ins" + index}
                    >
                      {val?.Process.map((valPro: any, indexPro: number) => (
                        <label
                          key={valPro?.id + "idt" + index}
                          className={`item ${
                            selectedProcessIds.includes(valPro.id) ? "-active" : ""
                          }`}
                          htmlFor={"ids" + valPro?.id}
                        >
                          <span>{valPro.name}</span>
                          {getSelectedProcessOfCategory(
                            currentCategory
                          ).indexOf(valPro?.id) >= 0 && (
                            <span
                              className="star hasNum"
                              data-number={
                                getSelectedProcessOfCategory(
                                  currentCategory
                                ).indexOf(valPro?.id) + 1
                              }
                            ></span>
                          )}
                          <input
                            checked={selectedProcessIds.includes(valPro.id)}
                            type="checkbox"
                            value={valPro?.id}
                            id={"ids" + valPro?.id}
                            onChange={(e: any) => handleSelectFavorite(e, valPro)}
                          />
                        </label>
                      ))}
                    </Tab.Pane>
                  ))}
                </div>
              </Tab.Container>
            </div>
          )}
        </div>
        <div className="modal-footer dflex dflex_end">
          <p className="text_bold mr60">{
            categoriesByType.map((category: any) => category.Process).flat().filter((item: any) => selectedProcessIds.includes(item.id)).length}工程選択中</p>
          <button
            className="btn btn-bdnone minw140 btn-deselect"
            type="button"
            data-request="close"
            data-modal="Selection"
            onClick={(e: any) => handleUnSelectedProcess()}
          >
            選択解除
          </button>
          <button
            className="btn btn-primary minw140 btn-select"
            type="button"
            onClick={handleSubmit}
          >
            完了
          </button>
        </div>
      </div>
    </ModalComfirm>
  );
};
