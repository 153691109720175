
type messageType = {
  [key: string]: any
};
export const message: messageType  = {
  success: '更新が完了しました。',
  insert_success: '登録が完了しました。',
  delete_success: '削除が完了しました。',
  failed: 'エラーが発生しました。しばらくしてからもう一度お試しください。',
  order_code_invalid: 'このコードは存在しません',
  order_code_exists: '注文コードはすでに存在します',
  order_not_process: '注文はまだプロセスを登録していません',
  order_remake_invalid: 'この指示書は再製作の技工物が登録されていません。',
  error_time_over: "サーバーが応答しませんでした"
}