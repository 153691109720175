import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface IState {
  statisticWeekPoints: any;
  filter: any;
}

const initialState: IState = {
  statisticWeekPoints: [],
  filter: {
    type: "month", // month, week
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    day: new Date().getDate(),
    department: null,
    branch: "all",
    week: moment({year: new Date().getFullYear(),  month: new Date().getMonth() + 1, day: new Date().getDate()}).isoWeek(),
  },
};

export const statisticWeekPointSlice = createSlice({
  name: "statisticWeekPoint",
  initialState,
  reducers: {
    resetStatisticWeekPoint(state) {
      state.statisticWeekPoints = [...initialState.statisticWeekPoints];
    },
    resetFilter(state) {
      state.filter = {...initialState.filter};
    },
    fetchStatisticWeekPoints(state, action) {
      state.statisticWeekPoints = action.payload;
    },

    updateFilter(state, action) {
      let filter = { ...state.filter };
      let data = action.payload;

      for (let prop in data) {
        filter[prop] = data[prop]
      }
      state.filter = filter;
    },

    setFilter(state, action) {
      let { key, val } = action.payload;
      let filter = { ...state.filter };
      // handle modals

      filter[key] = val;

      state.filter = filter;
    },
  },
});

export default statisticWeekPointSlice;
export const orderGroupReducer = statisticWeekPointSlice.reducer;
export const statisticWeekPointSliceAction = statisticWeekPointSlice.actions;
