import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRegisterFormFields, IRegisterSubmitField } from "@models/Register";
import FormValidation from "@/utils/validate";
import { fieldName } from "@/constants/validationFieldName";
import { IDataResponse } from "@/datasource/services/interface/IDataResponse";
import { ILabInfo } from "@/models/Lab";
import { number } from "yup";

interface SettingState {
  [key: string]: any;
  labo: any;
  branch: any;
  department: any;
  erection: any;
  denture: any;
  implant: any;
  mouthpiece: any;
  correct: any;
  child: any;
  partner: any;
  categories: any;
  employee: any;
  employeeSearch: any;
  material: { [key: string]: any };
  icons: [];
}

const objectData: any = {
                          items: [],
                          current_page: 1,
                          last_page: 1,
                          filter: {},
                          sort: {},
                        }

const initialState: SettingState = {
  labo: {},
  branch: objectData,
  reasons: objectData,
  reasonList: objectData,
  remake: objectData,
  department: objectData,
  erection: objectData,
  denture: objectData,
  implant: objectData,
  mouthpiece: objectData,
  correct: objectData,
  child: objectData,
  task: objectData,
  partner: {
    items: [],
    current_page: 1,
    last_page: 1,
    sort: {},
    type: 0,
    total: 0,
    filter_name: "",
    keyword: "",
  },
  clinics: {
    items: [],
    current_page: 1,
    last_page: 1,
    sort: {},
    type: 0,
    filter: "",
    total: 0,
  },
  employee: {
    items: [],
    current_page: 1,
    filter: {},
    last_page: 1,
    keyword: "",
    sort: {},
    type: 0,
  },
  employeeSearch: {
    items: [],
    current_page: 1,
    filter: {},
    last_page: 1,
    keyword: "",
    sort: {},
    type: 0,
  },
  categories: [],
  material: {},
  metalType: [],
  manufactures: [],
  process: [],
  manufacture: {},
  prodcutClass: {},
  lab: {},
  icons: [],
  materials: [],
  orderImages: {
    items: [],
    order_id: null,
  },

};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setLabo(state, action: PayloadAction<any>) {
      state.labo = { ...action.payload };
    },
    setLoadData(state, action: PayloadAction<any>) {
      const { data, last_page, current_page, type, total } = action.payload;

      switch (type) {
        case "branch":
          state.branch = {
            items: data,
            current_page,
            last_page,
            sort: action.payload.sort,
          };
          break;
        case "department":
          state.department = {
            items: data,
            current_page,
            last_page,
            sort: action.payload.sort,
          };
          break;
        case "erection":
        case "denture":
        case "implant":
        case "mouthpiece":
        case "correct":
        case "prodcutClass":
        case "lab":
        case "child":
          state[String(type)] = { items: data, current_page, last_page, total };
          break;
        case "partner":
        case "employee":
        case "employeeSearch":
        case "reasons":
        case "reasonList":
        case"remake":
        case"task":
        case "clinics":
          const {
            filter_name = null,
            keyword = null,
            sort = {},
            filter,
          } = action.payload;
          state[String(type)] = {
            items: data,
            current_page,
            last_page,
            filter_name,
            total,
            sort,
            keyword,
            filter,
          };
          break;
        case "categories":
        case "manufactures":
        case "icons":
        case "process":
        case "metalType":
        case "materials":
          state[String(type)] = [...data];
          break;
        case "manufacture":
        case "orderImages":
          state[String(type)] = { ...data };
          break;
        case "material":
          const { category_id } = action.payload;
          state.material[category_id] = {
            items: data,
            current_page,
            last_page,
            total,
          };
          break;
        //
      }
    },
    setStage(state, action: PayloadAction<any>) {
      const { data, filed } = action?.payload
      state[filed as string] = { ...state[filed as string], ...data }
    }
  },
});

const authReducer = settingSlice.reducer;
const { setLabo, setLoadData, setStage } = settingSlice.actions;
export { setLabo, authReducer, setLoadData, setStage };
