import dayjs from "dayjs";
import { url } from "@/constants/urlRrefix";
import React from "react";
import { Stream } from "stream";

export const renderZip = async (event: any, funcSetData: Function, funcRenderZip: Function, objecfncZip: Object | null = null ) => {
    await funcSetData(event)
    let  value  = event.target.value.replaceAll('-','')
    if( value.match( '^[0-9]{7}$' )  )
    return funcRenderZip(value, objecfncZip)

}


export const typeSort = (a : any, b: any, sort_by : any, sort_type : any) => {

    switch( sort_type )
    {
      case 'asc':
                if( typeof a[sort_by] == 'string' )
                  return ( a[sort_by]  < b[sort_by] ) ? -1 : 0
                // (a[sort_by]).localeCompare(b[sort_by]);
                  return a[sort_by] - b[sort_by];
                break;
      case 'desc':
                if( typeof a[sort_by] == 'string' )
                  return ( b[sort_by]  < a[sort_by] ) ? -1 : 0
                 //return (b[sort_by]).localeCompare(a[sort_by]);
                  return b[sort_by] - a[sort_by];
                break;
    }

  }

  export const getLabel = ( employee: any = null ) => {

    if( !employee )
      return {
        NotEntered : () => (<span className="statusregist" style={{ background:'#ED5555', color:'#fff' }} >未調整</span >),
        Unadjusted: () =>  (<span className="statusregist" style={{ background:"#51C17D" }}>調整済</span>),
        EmployeeSpan : ( { children, ...props }: any ) => {

                          if( ! props?.adjustedFlag )
                          return (<span> { children } </span> )

                          return (<span className={ props?.isSelected ? "clgreen" : "clred linethrough"  }> { children } </span> )
                      }
      }

      const  status  = employee?.status || 'holiday'

      let Html: any

      switch( status )
      {
        case 'holiday': Html = (<span className="statusregist" style={{ background:"#E9E9EB" }} >休み</span>); break
        case 'working': Html = (<span className="statusregist" style={{ background:"#518CED" }} >作業中</span>) ; break
        case 'go_out': Html = (<span className="statusregist" style={{ background:"#F0932B" }} >外出中</span>) ; break
        case 'not_working': Html = (<span className="statusregist" style={{ background:"#ED5555" }} >未作業</span>) ; break
        case 'rest': Html = (<span className="statusregist" style={{ background:"#51C17D" }} >休憩中</span>) ; break
      }

      return { Html }

  }

  export const typeSortByIndex = (a : any, b: any, sort_by : any, sort_type : any, sortFiled: Function ) => {

    const itemA = sortFiled(a)
    const itemB = sortFiled(b)


    switch( sort_type )
    {
      case 'asc':
                if( typeof itemA[sort_by] == 'string' )
                return ('' + itemA[sort_by]).localeCompare( itemB[sort_by]);
                return itemA[sort_by] - itemB[sort_by];
                break;
      case 'desc':
                if( typeof itemA[sort_by] == 'string' )
                return ('' +  itemB[sort_by]).localeCompare(itemA[sort_by]);
                return  itemB[sort_by] - itemA[sort_by];
                break;
    }

  }


  export const mapProcessProdcutSetting = (items :any) => {

    
    const { Process, Product }: any = items

    const prodcutItems = Product.map( (row:any) => {
      
      const { breakdown_flg } = row
      
      

      let procesMapping = Process.reduce( (cur: any, acc: any) => {
            const result:any = row.ProductProcesses.find( (valPro: any) => valPro?.process_id == acc?.id );

            if(result && !breakdown_flg )
              return [ ...cur, { ...acc, visible: true, 
                                        id: result?.id,
                                        time: result?.time || acc?.ProductCategoryProcess?.time,
                                        point: result?.point || acc?.ProductCategoryProcess?.point,
                                        idCategory: acc?.category_id 
                                } 
                    ]

            return [ ...cur, { ...acc, visible: false, 
                                       id: null, 
                                       time: -1, 
                                       point: -1, 
                                       idCategory: acc?.category_id 
                              } ]
      },[])

      const total_point = procesMapping.reduce((cur : number, a : any) => {
        let point: any = Number(a?.point)         
        return cur + ( a?.visible && (point >=0) ? point : 0 ) 
      }, 0)

      const total_time = procesMapping.reduce((cur : number, a : any) => {
        let time: any = Number(a?.time)
        return cur + ( a?.visible && (time >=0) ? time : 0 )
      } , 0)

      return { ...row, procesMapping, total_point, total_time }
    })

    return { ...items, Product: prodcutItems }
  }


  export const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
      <span
        onClick={(e: any) => {
          e.preventDefault();
          onClick(e);
        }}
        ref={ref}
      >
        {children}
      </span>
    )
  );

  export const CustomMenu = React.forwardRef(
    (
      { children, style, className, "aria-labelledby": labeledBy }: any,
      ref: any
    ) => (
      <div ref={ref} className={className}>
        {children}
      </div>
    )
  );

  export const changeDataProdcut = ( items: any, id: any,value: any, name: any ) =>  {

    const procesMapping: any = items?.procesMapping.map( (valp:any) => {
                                                if( valp?.id != Number(id) ) return ({...valp})

                                                let datap: any = {}  
                                                    datap[name] = value
                                              
                                                return { ...valp,  ...datap  }
                                            })

      const total_point = procesMapping.reduce((cur : number, a : any) => {
        let point: any = Number(a?.point)         
        return cur + ( a?.visible && (point >=0) ? point : 0 ) 
      }, 0)

      const total_time = procesMapping.reduce((cur : number, a : any) => {
        let time: any = Number(a?.time)
        return cur + ( a?.visible && (time >=0) ? time : 0 )
      } , 0)

    return { ...items, procesMapping, total_point,total_time  }
}
  

  export const validateCode = (value: any) => {
    const codeRegex = new RegExp(/^[-0-9A-Z ]+$/, "g");

    if (codeRegex.test(value) || !value) 
      return true;
    
    return false;
  };

  export const validateNumber = (n :any) => 
    ( n != Infinity ) 
      ?
        (Number(n) === n && n % 1 !== 0)
              ? Number(n).toFixed(2)
              : Number(n)
      : 0
  
  export const onlyNumber = (n: any) =>  (n == '' ) ? true : (/^[0-9]+$/.test( n ) ? n : '') 

  export const cachePathPrve = ( urlPrefix: any, type: "fetch" | "update" = "update" ) => {
    // ** get cahe
   
      let cache:any = localStorage.getItem( "pathPrefix" ) 
      
          cache = cache ? JSON.parse( cache ) : {}
      
      if( type == "fetch" )
        return (cache[urlPrefix?.prefix]  || urlPrefix?.path )

      let res: any = {}
          res[ urlPrefix?.prefix ] =  urlPrefix?.path
      
      return localStorage.setItem( "pathPrefix", JSON.stringify( { ...cache, ...res  } ) )
  }
  
  export const range = (start: any, stop: any, step: any) => Array.from( { length: (stop - start) / step + 1 }, (_, i) => start + i * step );

  export const chunk = ( array: any, chunkSize: number ) => {

      for (let i = 0; i < array.length; i += chunkSize) {
        array = array.slice(i, i + chunkSize);
      }

      return array
  }

  export const chunkString = ( data: any ) => 
    {
      return data.split(' ')
            .reduce( (acc:any, cur:any) => 
                ([ ...acc, ...( String(cur)?.match(/.{1,7}/g) || [] ) ])
            , [] )
          .join("-")
    }
  
  export const getFromTo = ( data: any , shiftTpye:any, type: any) =>
  {
    let timebetween:any 
    let { formTime, toTime } = data
    
    

    switch (shiftTpye) {
      case "end_of_month":
        
          //formTime = formTime.subtract(1, type as string )
        if( ["year"].includes(type) )
          formTime = formTime.startOf( "year" )

        toTime = toTime.endOf('month')
        timebetween = formTime.format("YYYY/MM/DD")+`~`+toTime.format( type == "month" ? "MM/DD" : "YYYY/MM/DD" );  
              
        break;
      default:

        if( ["year", "month"].includes(type) )
          formTime = formTime.subtract(1, type as string )

        formTime = formTime.add(1, "day" )
        timebetween = formTime.format("YYYY/MM/DD")+`~`+toTime.format( type == "month" ? "MM/DD" : "YYYY/MM/DD" );
        break;
    }
    
    if( !dayjs(formTime).valueOf() || !dayjs(toTime).valueOf() )
      timebetween = ""
    
    return { timebetween, between: { from: formTime, to: toTime } }
  }

  // ** GET TIME BETEEN

  export const getTWFromTo = ( data: any , shiftTpye:any ) =>
  {

    let { from, to } = data
    

    

    switch (shiftTpye) {
      case "end_of_month":
        
          from = from.startOf( "month" )
          to = to.endOf('month')
        break;

      default:
        
          from = from.subtract( 1, "month" )
          from = dayjs(`${from?.format("YYYY-MM")}-${shiftTpye}`)

          from = from.add(1, "day" )

          to = dayjs(`${to?.format("YYYY-MM")}-${shiftTpye}`)

        break;
    }
        
    return { from, to }
  }
  
  // using checked for sreen 32 EVMRE-1069
  export const handleCheckedEmployee = (formField: any, data: any) => formField.filter( (res:any) => ( res?.permission_id == data?.permission_id && res?.option_id == data?.option_id ) )?.length > 0;

  export const findOptionNext = (array: any, currentOption: any) => {
    
    // find permistion
    const yourOption = array.find( (res:any) => res.id == currentOption?.PermissionOption?.permission_id )?.Options || [] 

    let yourIndex = yourOption.findIndex( (res:any) => res?.id == currentOption?.PermissionOption?.option_id ) + 1
        yourIndex = yourIndex <= yourOption?.length ? yourIndex : 1

    const opFind = yourOption[yourIndex] || {}
    
    return { permission_id: opFind?.PermissionOption?.permission_id, option_id: opFind?.PermissionOption?.option_id  }
  }

  export const mapPermissionList = (array: any, userRole: any, existYourDepartment?: any) => {
    
    return array.reduce((acc : any, cur : any) => {

        const res = cur?.Options
                .filter( (val: any) => {
            
                                switch(userRole){
                                  case 'lab_super_admin':
                                  case 'lab_admin':
                                    return val?.PermissionOption?.lab_admin_default
                                  case 'lab_app':
                                    return val?.PermissionOption?.lab_app_default
                                  case 'lab_staff':
                                    return val?.PermissionOption?.lab_staff_default
                                }

                })
                .map( (resP : any) => {
                  //value: 'department'
                  if( !existYourDepartment && ['department'].includes( resP?.value ) )
                   return findOptionNext( array, resP )

                  return ( { permission_id: resP?.PermissionOption?.permission_id, option_id: resP?.PermissionOption?.option_id,  } )
                } )

        return ([...acc, ...res ])
    }, []);
    
  }

  export const renderOption = (permission: any, optionFiled: any, index: number) => {

    const { isDisabled, isRetired, formField, existYourDepartment, handleCheckedEmployee, setFormField } = optionFiled

    
    return (
        <tr key={`permissionSow-${index}`}>
          <td>{ permission?.name }</td>
            <td className="middle">
              <ul className="selectstyle dflex selectstyle-rouder">
                { permission?.Options.map((op: any, ind: number) => 
                   (
                      <li key={`permis-${index}-op-${ind}`}>
                        <label>
                          <input type="checkbox" name="option_ids" value={op?.PermissionOption?.option_id} data-permission={op?.PermissionOption?.permission_id} onChange={setFormField}
                              disabled={ ( ['department'].includes( op?.value as string ) && !existYourDepartment ) || isDisabled ||  isRetired?.retired_flg  }
                              checked={ handleCheckedEmployee( formField?.permissions, op?.PermissionOption ) } />
                          {op.name}
                        </label>
                      </li>
                    )
                )
                }
              </ul>
            </td>
          </tr>
      )
    
  }


  export const mapPermissionByTrigger = (formfiled: any, array: any) => {
      
      return formfiled.permissions.map( (val:any) => {

          const listoption = array.find( (pr:any) => pr?.id == val?.permission_id )?.Options

          const curOption = listoption.find( (op:any) => op?.id ==  val?.option_id )

          if( !['department'].includes( curOption?.value ) )
            return ( { ...val } )

          let yourIndex = listoption.findIndex( (res:any) => res?.id == curOption?.PermissionOption?.option_id ) + 1
          yourIndex = yourIndex <= listoption?.length ? yourIndex : 1
  
          const opFind = listoption[yourIndex] || {}
        
          return { permission_id: opFind?.PermissionOption?.permission_id, option_id: opFind?.PermissionOption?.option_id  }
          
      } )
    
    
  }

  export const findFiledName = (pathname : string = "") => {
    
    return url.find( (val:any) =>  
                        {
                          let path = ( pathname || "" )
                          
                          return ( path.search( val?.url as string )  >= 0 )
                        } 
                    )?.filedName || ""
                     
  }

  export const checkDisableLink = (role: any, urlCurrent: any) => {

    const checkRole = role.find( (res:any) => res?.permission== findFiledName(urlCurrent) )
    
    return ['hidden', 'disable'].includes( checkRole?.option ) ? "disabedLink" : ""
  
}

export const translate = (pathName: string = "") => {

  const url:any = {
                "部署別実績" : "/statistic/department"
              }
            
  return url[pathName] || ""

}