import React, { useEffect, useState } from "react";
import { HookContext, TContextHook } from "@/context/hook";
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import { EmptyTask } from "@/components/tasks/blocks/emptyTask";
import { Task } from "@/components/tasks/blocks/task";
import { taskSliceAction } from "@/store/task";
import Link from "next/link";
import { OrderViewModel } from "@/modelviews/order";
import { orderSliceAction } from "@/store/order";
import { mapOrder } from "@/helpers";
import { getCookie, deleteCookie } from 'cookies-next';
import { socket } from '@/pages';
import { ITask, statisticsType, TaskModal } from '@/models/Task';
import moment from 'moment';
import { setBackHistoryUrl, setLoading, setMsg } from "@/store/global";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSetting } from "@/components/mixins/statistic/useSetting";
import dayjs from "dayjs";

export const ListTask = () => {
  const { settingLab }: any = useSetting()

  const { current_now, minTime, maxTime } = {
                                              current_now: settingLab?.current_now || dayjs(),
                                              minTime: settingLab?.minTime || dayjs(),
                                              maxTime: settingLab?.maxTime || dayjs(),
                                            }
  

  socket.emit("authenticate", getCookie("access_token"), (data: any) => {});
  socket.off("task");
  socket.on("task", async (data) => {
    getData(stateTask.task.currentPage);

    const res = await taskVM.getTaskLog({
      date: moment().format("YYYY-MM-DD"),
    });

    hooks.dispatch(
      taskSliceAction.saveLogs(res.data)
    )

    let {from, to, type } = stateTask.statistics
    
    const statistics = await taskVM.getStatistic({
      from: type == statisticsType.month ? from : minTime?.format("YYYY-MM-DD HH:mm:ss"),
      to: type == statisticsType.month ? to : maxTime?.format("YYYY-MM-DD HH:mm:ss")
    });
    
    await hooks.dispatch(
      taskSliceAction.updateStatistics([
        {
          key: "data",
          val: {
            ...statistics.data,
            pause_time: statistics.data.pause_time && {
              ...statistics.data.pause_time,
              start_time_local: moment()
            }
          }
        }
      ])
    )
  })
  
  const hooks = React.useContext(HookContext) as TContextHook;
  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);
  const orderVM = container.resolve<OrderViewModel>(OrderViewModel);
  taskVM.setHooks(hooks);
  orderVM.setHooks(hooks);

  // get order owner
  const getOrders = () => {
    hooks.dispatch(setLoading(true))
    const params = {
      own_flg: 1,
      limit: Number.MAX_VALUE,
      include_product: 1,
      without_old_completed: 1,
    };
    orderVM.fetchOrders(params).then((res: any) => {
      if (res.success) {
        let orders = res.data.data;
        orders.reverse().forEach((order: any) => {
          let orderMapping = mapOrder(order);
          hooks.dispatch(orderSliceAction.addOrder(orderMapping));
        });
        hooks.dispatch(setBackHistoryUrl("/"))
        hooks.router.push({
          pathname: "/order/process",
          query: { additional: true },
        });
      }else {
        hooks.dispatch(setMsg({text: res.message, status: -1}))
      }
      hooks.dispatch(setLoading(false))
    });
  };
  const stateTask = hooks.useSelector((state: any) => state.task);
  
  const getData = async(page: number) => {
    hooks.dispatch( setLoading(true) );
    const res = await taskVM.getTask({page: page, limit: stateTask.task.limit, hide_input_process: 1, without_old_completed: 1});
    if(res.data?.last_page < page) {
      await getData(res.data?.last_page);
    }
    else {
      hooks.dispatch(
        taskSliceAction.updateTask([
          {
            key: "data",
            val: res.data?.data.map((item: any) => {
              return {
                ...item,
                clientTime: moment()
              }
            })
          },
          {
            key: "lastPage",
            val: res.data?.last_page,
          },
          {
            key: "currentPage",
            val: page,
          },
        ])
      ); 
    }
    hooks.dispatch( setLoading(false) );

  };

  const openDoneTaskModal = (task: ITask) => {
    hooks.dispatch(
      taskSliceAction.updateStore([
        {
          key: "currentTask",
          val: task,
        },
        {
          key: "modal",
          val: TaskModal.doneTask,
        },
      ])
    );
  };
  
  const from_login = getCookie("first_login");
  
  useEffect(() => {
    if(from_login) {
      getData(1);
      deleteCookie("first_login");
    }
    else {
      getData(stateTask?.task?.currentPage > 0 ? stateTask?.task?.currentPage :  1);
    }

    return () => { socket.off("task"); }

  }, []);

  const settingsSlider = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // rows: 1,
    initialSlide: from_login ? 0 : stateTask.task.currentPage - 1,
    afterChange: (page: number) => {getData(page + 1)},
    dots: true
  };
  
  return stateTask.task.data?.length > 0 ? (
    <div className="rightside">
      <div className="blockBusiness_regist">
        <Slider {...settingsSlider}>
          {
            (new Array(stateTask.task.lastPage ?? 0)).fill(1).map((i, index) => 
              (
                <div className="sliderdflex" key={`sliderdflex_${index}`}>
                  { stateTask.task.currentPage == index +1 && stateTask.task.data?.map((item: any, key: number) => (
                    <Task
                      key={key}
                      task={item}
                      startTask={async(taskId: number) => {
                        const res = await taskVM.startTask(taskId);
                        hooks.dispatch(
                          taskSliceAction.updateOneTask(res.data)
                        );
                      }}
                      pauseTask={async(taskId: number, countTime: number) => {
                        hooks.dispatch(
                          taskSliceAction.updateOneTask({
                            ...item,
                            status: "pause",
                            working_time: countTime
                          })
                        );
                        const res = await taskVM.pauseTask(taskId, countTime);
                        hooks.dispatch(
                          taskSliceAction.updateOneTask(res.data)
                        );
                      }}
                      doneTask={() => {openDoneTaskModal(item)}}
                    ></Task>
                  ))}
                </div>
              )
            )
          }
        </Slider>
      </div>
      {/* <div className="blockpager">
        <ul className="dflex">
          {Array(stateTask.task.lastPage)
            .fill(1)
            .map((item: any, index: number) => (
              <li
                className={`${
                  index + 1 == stateTask.task.currentPage ? "active" : ""
                }`}
                onClick={() => {
                  getData(index + 1);
                }}
                key={index}
              >
                <span></span>
              </li>
            ))}
        </ul>
      </div> */}
      <div className="blockBtn">
        <ul className="dflex">
          <li>
            <Link className="btn btn-hasInstruc" href="/order/owners"> 所持している <br /> 指示書{" "} </Link>
          </li>
          <li>
            <a
              className="btn btn-addInstruc"
              onClick={() => {
                alert("Comming soon");
              }}
            >
              現在作業予定の
              <br />
              工程へ指示書追加
            </a>
          </li>
          <li>
            <a
              className="btn btn-addProcess active"
              onClick={(e) => {
                getOrders();
              }}
            >
              所持指示書へ
              <br />
              工程追加
            </a>
          </li>
        </ul>
      </div>
    </div>
  ) : (
    <EmptyTask></EmptyTask>
  );
};
