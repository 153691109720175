import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";
import { imgAssets } from "@/constants/img";
import moment from "moment";
import { HookContext, TContextHook } from "@/context/hook";
import { TaskViewModel } from "@/modelviews/task";
import { container } from "@/di";
import { taskSliceAction } from "@/store/task";
import { AuthViewModel } from "@/modelviews/auth";
import Link from "next/link";
import { setCookie } from "cookies-next";
import useSetProfile from "@/layout/setting/useSetProfile";
import { useLoginProcess } from "@/components/login/useLoginProcess";
import { useOnClickOutside } from "usehooks-ts";
import { setModal } from "@/store/global";

interface NavigatorProp {
  pauseTime: (type: string) => void;
}

export const Navigator = (props: NavigatorProp) => {
  const [time, setTime] = useState(["", "", ""]);
  
  const { handleLoglout } = useLoginProcess()

  const hooks = React.useContext(HookContext) as TContextHook;
  const stateTask = hooks.useSelector((state: any) => state.task);
  const stateProfile = hooks.useSelector((state: any) => state.profile);

  const authViewModel = container.resolve<AuthViewModel>(AuthViewModel);
  authViewModel.setHooks(hooks);

  const taskVM = container.resolve<TaskViewModel>(TaskViewModel);
  taskVM.setHooks(hooks);

  const getDayOfWeek = (date: Date) => {
    const strings = ["日", "月", "火", "水", "木", "金", "土"];
    return strings[date.getDay()];
  };

  useEffect(() => {
    hooks.dispatch(
      taskSliceAction.showSidebar(false)
    );

    hooks.dispatch(taskSliceAction.showLogoutPopup(false));

    setTime([
      moment().format("YYYY年MM月DD日") + `(${getDayOfWeek(new Date())})`,
      moment().format("HH:mm"),
      moment().format("ss"),
    ]);

    const loop = setInterval(() => {
      setTime([
        moment().format("YYYY年MM月DD日") + `(${getDayOfWeek(new Date())})`,
        moment().format("HH:mm"),
        moment().format("ss"),
      ]);
    }, 1000);
    if (!stateProfile.id) {
      authViewModel.getProfile();
    }

    return () => {
      clearInterval(loop);
    }
  }, []);

  const refMenu = useRef(null);
  const handleClickOutside = () => {
    if(stateTask.showLogoutPopup)
    {
      hooks.dispatch(taskSliceAction.showLogoutPopup(false));
    }
  };
  useOnClickOutside(refMenu, handleClickOutside);

  hooks.useEffect(() => {
    if(stateTask.showLogoutPopup)
    {
      authViewModel.getProfile();
    }
  }, [stateTask.showLogoutPopup])

  return (
    <div className="blockBtnNav shadow">
      <div className="blockTime">
        <div className="text">
          <p className="text_bold">{time[0]}</p>
          <p className="time text_bold fontArial fz30">
            {time[1]}
            <small className="text_bold">:{time[2]}</small>
          </p>
        </div>
        <div className="icon dflex">
          {/* <div className="icon_infor">
            <Image unoptimized={true} src={imgAssets.iconInfo} alt="" />
          </div> */}
          <div className="icon_user">
            <Image
              unoptimized={true}
              src={imgAssets.iconUser}
              alt=""
              onClick={() => {
                hooks.dispatch(
                  taskSliceAction.showLogoutPopup(true)
                );
              }}
            />
          </div>
          {stateTask.showLogoutPopup && (
            <div className="divlogout" ref={refMenu}>
              <ul>
              <li style={{borderBottom: "1px solid #999"}}>
                  <a>
                    <Image
                      unoptimized={true}
                      src={imgAssets.iconUser}
                      alt=""
                      style={{width: "20px"}}
                    />
                    <span className="usernameStyle">{`${stateProfile.name_sei} ${stateProfile.name_mei}`}</span>
                  </a>
                </li>
                <li>
                {/* href={`/setting/employee/edit/${stateProfile.id}`} */}
                  <a  className="pointer" 
                      onClick={() => { 
                         hooks.dispatch( setModal({ modalId: "Selection", isShow: true, data: null }) ) 
                     hooks.dispatch(taskSliceAction.showLogoutPopup(false))
                    }} > <Image unoptimized={true} src={imgAssets.icoSeting} alt="" />お気に入り工程 </a>
                </li>
                <li>
                  <a
                    className="pointer"
                    onClick={handleLoglout}
                  >
                    <Image
                      unoptimized={true}
                      src={imgAssets.icoLogout}
                      alt=""
                    />
                    ログアウト
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <ul className="blockBtn dflex">
        <li className="btn-container">
          <Link href={`/order/create`} className="btn btn-primary">
            業務登録
          </Link>
          <a
            className="btn btn-red btn-red2"
            onClick={() => {
              props.pauseTime("rest");
            }}
          >
            休憩
          </a>
          <a
            className="btn btn-orange"
            onClick={() => {
              props.pauseTime("go_out");
            }}
          >
            外出
          </a>
          <a
            className="btn btn-dblue"
            onClick={() => {
              hooks.dispatch(taskSliceAction.showSidebar(true));
              authViewModel.getProfile();
            }}
          >
            メニュー
          </a>
        </li>
        <li className="w48pc">
          <Link href={`/order/images`} className="btn btn-pink">
            預かり写真撮影
          </Link>
        </li>
        <li className="w48pc">
          <Link href={`/order/manage`} className="btn btn-pink2">
            預かり写真一覧
          </Link>
        </li>
        <li className="w48pc">
          <Link
            className="btn btn-wgreen"
            href={`/order/images?type=finish`}
          >
            完成写真撮影
          </Link>
        </li>
        <li className="w48pc">
          <Link
            className="btn btn-green"
            href={`/order/history`}
          >
            技工物履歴
          </Link>
        </li>
      </ul>

    </div>
  );
};
