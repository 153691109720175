import type { ISettingProcessRepository } from "@repositories/setting/process";
import { BaseViewModel } from "@/modelviews/BaseViewModel";
import "reflect-metadata";
import { inject, injectable } from "inversify";
import { TYPES } from "@/di/types";
import { msg } from "@msg";

@injectable()
export class SettingProcessViewModel extends BaseViewModel {
  constructor(
    @inject(TYPES.SettingProcessRepository)
    private settingProcessRepo: ISettingProcessRepository
  ) {
    super();
  }

  async updatFavoriteProcess(params: any) : Promise<any> {
    return await this.settingProcessRepo.updatFavoriteProcess(params);
  }

  async fetchAll(params: any): Promise<any> {
    let res = await this.settingProcessRepo.fetchAll(params);
    if (res.success && res.data) {
      return res.data;
    } else {
      this.setMsg(res.message, -1);
    }
    return;
  }

  async sortOrder(data: any): Promise<any> {
    const res = await this.settingProcessRepo.sortOrder(data);
    if (res.success && res.data) {
      return res;
    } else {
      this.setMsg(res.message, -1);
    }
    return;
  }

  async fetch(id: number, params: any): Promise<any> {
    let res = await this.settingProcessRepo.fetch(id, params);
    if (res.success && res.data) {
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async update(id: number, data: any): Promise<any> {
    let res = await  this.settingProcessRepo.update(id, data);
    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async updateField(id: number, data: any): Promise<any> {
    let res = await this.settingProcessRepo.updateField(id, data);
    if (res.success) {
      this.setMsg(msg.updateSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async delete(id: number): Promise<any> {
    let res = await  this.settingProcessRepo.delete(id);

    if (res.success) {
      this.setMsg(res.message, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async create(data: any): Promise<any> {
    let res = await this.settingProcessRepo.create(data);

    if (res.success && res.data) {
      this.setMsg(msg.createSucceed, 1);
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }

  async createCategory(data: any): Promise<any> {
    const formData = {
      name: data.name,
      type: data.type,
    };
    let res = await this.settingProcessRepo.createCategory(formData);
    if (res.success && res.data) {
      return res.data;
    } else {
      this.setMsg(res.message, -1);
    }
    return;
  }

  async fetchCategories(params: any): Promise<any> {
    let res = await this.settingProcessRepo.fetchCategories(params);
    if (res.success && res.data) {
      return res.data;
    } else {
      this.setMsg(res.message, -1);
    }
    return res;
  }
}
