const TYPES = {
  AuthRepository: Symbol.for("AuthRepository"),
  AuthService: Symbol.for("AuthService"),
  AuthViewModel: Symbol.for("AuthViewModel"),
  ContextHook: Symbol.for("ContextHook"),
  BaseViewModel: Symbol.for("BaseViewModel"),
  RegisterService: Symbol.for("RegisterService"),
  RegisterRepository: Symbol.for("RegisterRepository"),
  VerifyService: Symbol.for("VerifyService"),
  SearchLabService: Symbol.for("SearchLabService"),
  PrefCityService: Symbol.for("PrefCityService"),
  PrefCityRepository: Symbol.for("PrefCityRepository"),
  CheckExistInfoService: Symbol.for("CheckExistInfoService"),
  CardService: Symbol.for("CardService"),
  CardRepository: Symbol.for("CardRepository"),
  LaboService: Symbol.for("LaboService"),
  LaboRepository: Symbol.for("LaboRepository"),
  BranchService: Symbol.for("BranchService"),
  BranchRepository: Symbol.for("BranchRepository"),
  DepartmentService: Symbol.for("DepartmentService"),
  DepartmentRepository: Symbol.for("DepartmentRepository"),
  SettingProcessService: Symbol.for("SettingProcessService"),
  SettingProcessRepository: Symbol.for("SettingProcessRepository"),
  SettingIconService: Symbol.for("SettingIconService"),
  SettingIconRepository: Symbol.for("SettingIconRepository"),
  SettingProductService: Symbol.for("SettingProductService"),
  SettingProductRepository: Symbol.for("SettingProductRepository"),
  SettingLabService: Symbol.for("SettingLabService"),
  SettingLabRepository: Symbol.for("SettingLabRepository"),
  SettingEmployeeService: Symbol.for("SettingEmployeeService"),
  SettingEmployeeRepository: Symbol.for("SettingEmployeeRepository"),
  SettingPermissionService: Symbol.for("SettingPermissionService"),
  SettingPermissionRepository: Symbol.for("SettingPermissionRepository"),
  ReasonService: Symbol.for("ReasonService"),
  ReasonRepository: Symbol.for("ReasonRepository"),
  PartnerService: Symbol.for("PartnerService"),
  PartnerRepository: Symbol.for("PartnerRepository"),
  MaterialService: Symbol.for("MaterialService"),
  MaterialRepository: Symbol.for("MaterialRepository"),
  ImplantService: Symbol.for("ImplantService"),
  ImplantRepository: Symbol.for("ImplantRepository"),
  VariationService: Symbol.for("VariationService"),
  VariationRepository: Symbol.for("VariationRepository"),
  OrderService: Symbol.for("OrderService"),
  OrderRepository: Symbol.for("OrderRepository"),
  TaskService: Symbol.for("TaskService"),
  TaskRepository: Symbol.for("TaskRepository"),
  OrderGroupService: Symbol.for("OrderGroupService"),
  OrderGroupRepository: Symbol.for("OrderGroupRepository"),
  StatisticClinicRepository: Symbol.for("StatisticClinicRepository"),
  StatisticClinicService: Symbol.for("StatisticClinicService"),
  StatisticClinicViewModel: Symbol.for("StatisticClinicViewModel"),
  StatisticStaffRepository: Symbol.for("StatisticStaffRepository"),
  StatisticStaffService: Symbol.for("StatisticStaffService"),
  StatisticStaffViewModel: Symbol.for("StatisticStaffViewModel"),
  StatisticStaffPointService: Symbol.for("StatisticStaffPointService"),
  StatisticStaffPointRepository: Symbol.for("StatisticStaffPointRepository"),
  StatisticDepartmentService: Symbol.for("StatisticDepartmentService"),
  StatisticDepartmentRepository: Symbol.for("StatisticDepartmentRepository"),
  StatisticWeekPointService: Symbol.for("StatisticWeekPointService"),
  StatisticWeekPointRepository: Symbol.for("StatisticWeekPointRepository"),
  StatisticService: Symbol.for("StatisticService"),
  StatisticRepository: Symbol.for("StatisticRepository"),
  StatisticRankingService: Symbol.for("StatisticRankingService"),
  StatisticRankingRepository: Symbol.for("StatisticRankingRepository"),
};

export { TYPES };
